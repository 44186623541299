import React from "react";
import "./style.css";
import { Footermain } from "./Footermain";

export const Termsandcondtion = () => {
  return (
    <>
      <div className="container mx-auto text-center PrivacyandPolicy">
        <div>Terms and Condtion</div>
      </div>
      <div className="container mx-auto pandpcontent p-5">
        <p>
          Welcome to Ayya Wins Study Foundation. These terms and conditions
          outline the rules and regulations for the use of our website.
          <br />
          By accessing this website, you agree to accept these terms and
          conditions. Do not continue to use Ayya Wins Study Foundation if you
          do not agree to all of the terms and conditions stated on this page.
        </p>
        <h5>Intellectual Property Rights</h5>
        <p>
          Unless otherwise stated, Ayya Wins Study Foundation and/or its
          licensors own the intellectual property rights for all material on
          this website. All intellectual property rights are reserved.
        </p>

        <p className="pt-10">
          You may access this from Ayya Wins Study Foundation for your own
          personal use subjected to restrictions set in these terms and
          conditions.
        </p>
        <h5>Assignment</h5>
        <p>
          The Ayya Wins Study Foundation is allowed to assign, transfer, and
          subcontract its rights and/or obligations under these terms without
          any notification. However, you are not allowed to assign, transfer, or
          subcontract any of your rights and/or obligations under these terms.
        </p>
        <h5>Governing Law & Jurisdiction</h5>
        <p>
          These terms will be governed by and interpreted in accordance with the
          laws of the State of Tamil Nadu, India, and you submit to the
          non-exclusive jurisdiction of the state and federal courts located in
          Tamil Nadu for the resolution of any disputes.
        </p>

        <h5>User Account, Password, and Security:</h5>
        <p>
          Upon registration on our Website, either through your Facebook account
          or our native registration process, you are responsible for
          maintaining the confidentiality of your password and account. You
          agree to notify us immediately of any unauthorized use of your account
          and to ensure secure access.
        </p>
        <h5>Use of Information</h5>
        <p>
          Upon registration on our website, whether through your Facebook
          account or our native registration process, it is crucial to uphold
          the confidentiality of your password and account details. You bear the
          responsibility of selecting a robust, unique password, refraining from
          sharing it with others, and safeguarding it against unauthorized
          access. Should you detect any unauthorized activity or suspect a
          breach of security, immediate notification to us is imperative.
          Additionally, take proactive measures to ensure secure access to your
          account, such as logging out after each session, especially on shared
          or public devices. Keeping your account information accurate and
          promptly updating any changes is essential for maintaining account
          security.
        </p>
      </div>
      <Footermain />
    </>
  );
};
