import React, { useState } from 'react';
import axios from 'axios';
import './register.css';
import righttick from "../images/greentick.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faLock, faPhone, faEnvelope, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faXTwitter, faGoogle, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import login from '../images/log.svg';

export const SchoolRegister = () => {
  // State to store form values
  const [formData, setFormData] = useState({
    name: '',
    school: '',
    correspond: '',
    mobile: '',
    email: ''
  });

  // State to handle form errors
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false);

  // State to handle success/error feedback
  const [responseMessage, setResponseMessage] = useState('');

  // Function to handle input change and update the form data state
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Function to validate form fields
  const validateForm = () => {
    let errors = {};
    if (!formData.name) errors.name = 'Name is required';
    if (!formData.school) errors.school = 'School name is required';
    if (!formData.correspond) errors.correspond = 'Correspond name is required';
    if (!formData.mobile) errors.mobile = 'Mobile number is required';
    if (!formData.email) errors.email = 'Email is required';
    return errors;
  };

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateForm();
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      setIsSubmitting(true);

      try {
        const response = await axios.post(
          'https://api.ayyawinstudyfoundation.org/api/schools/register',
          formData,
          {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
            //   'Authorization': 'Bearer 14|T2SoAtoI1faXyaIkFMnxGL3m8dSArVyTd079oQA3',
            },
          }
        );

        setIsSubmitting(false);
        setIsRegistered(true); // Show success page
        setResponseMessage('Registration successful!');
        console.log(response.data);
      } catch (error) {
        setIsSubmitting(false);
        // Handle validation errors returned from the API
        if (error.response && error.response.data.errors) {
          setFormErrors(error.response.data.errors);
        } else {
          setResponseMessage('Registration failed. Please try again.');
        }
        console.error(error);
      }
    }
  };

  if (isRegistered) {
    // Success message after registration
    return (
<div className="min-h-screen succestick bg-blue-100 flex items-center justify-center">
  <div className="bg-white p-10 rounded-lg shadow-lg max-w-md text-center">
    <img src={righttick} alt="Success" width="100px" className="mx-auto mb-4" />
    <h2 className="text-3xl font-bold text-blue-700">Registration Successful!</h2>
<p className="text-lg mt-4 text-gray-700">Thank you for registering with us!</p>
{/* <p className="text-lg mt-2 text-gray-700">You will receive a confirmation email shortly.</p>
<p className="text-lg mt-2 text-gray-700">We're excited to have you on board!</p> */}

    <button
      onClick={() => { window.location.href = "/"; }}
      className="mt-6 px-6 py-3 font-semibold text-white bg-blue-500 hover:bg-green-600 rounded-md transition duration-300"
    >
      Continue
    </button>
  </div>
</div>
    );
  }

  return (
    <div className="container-mainn">
      <div className="forms-container">
        <div className="signin-signup">
          <form onSubmit={handleSubmit} className="sign-in-form">
            <h2 className="title">Register Here</h2>
            
            <div className={`input-reg-field ${formErrors.name ? 'error' : ''}`}>
              <FontAwesomeIcon color='#6666' icon={faUser} size="md" />
              <input
                type="text"
                placeholder="Name"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
              />
            </div>
            {formErrors.name && <p className="error-text">{formErrors.name}</p>}


            <div className={`input-reg-field ${formErrors.school ? 'error' : ''}`}>
              <FontAwesomeIcon color='#6666' icon={faLock} size="md" />
              <input
                type="text"
                placeholder="School Name"
                name="school"
                value={formData.school}
                onChange={handleInputChange}
              />
            </div>
            {formErrors.school && <p className="error-text">{formErrors.school}</p>}


            <div className={`input-reg-field ${formErrors.correspond ? 'error' : ''}`}>
              <FontAwesomeIcon color='#6666' icon={faUser} size="md" />
              <input
                type="text"
                placeholder="Corresponding Name"
                name="correspond"
                value={formData.correspond}
                onChange={handleInputChange}
              />
            </div>
            {formErrors.correspond && <p className="error-text">{formErrors.correspond}</p>}


            <div className={`input-reg-field ${formErrors.mobile ? 'error' : ''}`}>
              <FontAwesomeIcon color='#6666' icon={faPhone} size="md" />
              <input
                type="text"
                placeholder="Phone Number"
                name="mobile"
                value={formData.mobile}
                onChange={handleInputChange}
              />
            </div>
            {formErrors.mobile && <p className="error-text">{formErrors.mobile}</p>}


            <div className={`input-reg-field ${formErrors.email ? 'error' : ''}`}>
              <FontAwesomeIcon color='#6666' icon={faEnvelope} size="md" />
              <input
                type="text"
                placeholder="Email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
              />
            </div>
            {formErrors.email && <p className="error-text">{formErrors.email}</p>}


            <button type="submit" className="btn solid" disabled={isSubmitting}>
              {isSubmitting ? 'Registering...' : 'Register'}
            </button>

            {responseMessage && (
              <p className="response-message">{responseMessage}</p>
            )}

            <p className="social-text">Follow us for updates</p>
            <div className="social-media">
              <a href="#" className="social-icon">
                <FontAwesomeIcon color="blue" icon={faFacebook} size="md" />
              </a>
              <a href="#" className="social-icon">
                <FontAwesomeIcon color="black" icon={faXTwitter} />
              </a>
              <a href="#" className="social-icon">
                <FontAwesomeIcon color="#DB4437" icon={faGoogle} size="md" />
              </a>
              <a href="#" className="social-icon">
                <FontAwesomeIcon color="#5995fd" icon={faLinkedin} size="md" />
              </a>
            </div>
          
          </form>
        </div>
      </div>

      <div className="panels-container">
        <div className="panel left-panel">
          <div className="content">
            <h3>Ayyawin Foundation</h3>
            <p>
              Register and join us to enjoy exciting benefits. Be part of our
              community and contribute to making a positive impact!
            </p>
          </div>
          <img src={login} className="image" alt="Login" />
        </div>
      </div>
    </div>
  );
};
