import React, { useEffect, useState } from "react";
import logo from "../images/logod.jpeg";
import arrow from "../images/SVG.png";
import "./style.css";
import { Link } from "react-router-dom";
import { Transition } from "@headlessui/react";
import { useSelector } from "react-redux";
import { userData } from "../store/user/selectors";
import menuicon from "../images/menu-icon.png";
import { getUserdata } from "../utilities/sessionexpiry";

export const Navbarmain = () => {
  // State to manage mobile menu visibility
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  // Function to toggle mobile menu
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const [signin, setSignin] = useState(false); // Default to false initially
  const userData = getUserdata(); //JSON.parse(localStorage.getItem("userData"));

  useEffect(() => {
    if (userData && Object.keys(userData).length > 0) {
      setSignin(true); // Data exists
    } else {
      setSignin(false); // No data
    }
  }, []); // Run this effect only once on initial render
  console.log(signin); //

  const handleLogout = () => {
    localStorage.removeItem("userData");
    localStorage.removeItem("usersetting");
    // Dispatch action to clear user data from Redux state
  };
  return (
    <nav className="container  mx-auto text-white py-4 px-6 flex items-center justify-between">
      {/* Logo on the left */}
      <div className="flex items-center">
        <Link to="/">
          <img src={logo} alt="Logo" className="h-16" />
        </Link>
      </div>

      {/* Mobile Menu Icon */}
      <div className="md:hidden">
        <button
          onClick={toggleMobileMenu}
          className="text-white focus:outline-none"
        >
          {/* {isMobileMenuOpen ? "Close" : "Menu"} */}
        </button>
      </div>

      {/* Menu items in the center */}
      <div
        className={`md:flex items-center justify-center flex-grow navbartext ${
          isMobileMenuOpen ? "block" : "hidden"
        }`}
      >
        <a href="/" className="nav-link">
          Home
        </a>
        <a href="#about" className="nav-link">
          About
        </a>
        <a href="#features" className="nav-link">
          Reviews
        </a>
        <a href="#blog" className="nav-link">
          Blog
        </a>
        <a href="#contact" className="nav-link">
          Contact
        </a>
      </div>

      {/* Sign in button on the right corner */}
      {signin ? (
        <a
          href="/"
          className="bg-black hidden md:flex text-white px-5 py-4 flex items-center"
          style={{ whiteSpace: "nowrap" }}
          onClick={() => {
            handleLogout();
          }}
        >
          Log out <img src={arrow} alt="" className="ml-3" />
        </a>
      ) : (
        <Link
          to="/signin"
          className="bg-black hidden md:flex text-white px-5 py-4 flex items-center"
          style={{ whiteSpace: "nowrap" }}
        >
          Sign in <img src={arrow} alt="" className="ml-3" />
        </Link>
      )}
      <div className="fixed  overflow-hidden z-50">
        {/* Overlay */}
        <Transition
          show={isOpen}
          enter="transition-opacity duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className="absolute inset-0 bg-black opacity-50"
            onClick={() => setIsOpen(false)}
          ></div>
        </Transition>

        {/* Menu */}
        <Transition
          show={isOpen}
          enter="transition ease-out duration-300 transform"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
        >
          <div className="fixed inset-y-0 left-0 w-64 bg-white shadow-xl">
            <div className="flex justify-between items-center p-4">
              <Link to="/">
                <img src={logo} alt="Logo" className="h-16" />
              </Link>
              <button
                className="text-gray-500 focus:outline-none"
                onClick={() => setIsOpen(false)}
              >
                <svg
                  className="w-6 h-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <nav className="py-4">
              <ul>
                <li className="px-4 py-2 text-black hover:bg-gray-100 cursor-pointer uppercase">
                  <a href="/">Home</a>
                </li>
                <li className="px-4 py-2 text-black hover:bg-gray-100 cursor-pointer uppercase">
                  <a href="#about">About</a>
                </li>
                <li className="px-4 py-2 text-black hover:bg-gray-100 cursor-pointer uppercase">
                  <a href="#features">Features</a>
                </li>
                <li className="px-4 py-2 text-black hover:bg-gray-100 cursor-pointer uppercase">
                  <a href="#reviews">Reviews</a>
                </li>
                <li className="px-4 py-2 text-black hover:bg-gray-100 cursor-pointer uppercase">
                  <a href="#blog">Blog</a>
                </li>
                <li className="px-4 py-2 text-black hover:bg-gray-100 cursor-pointer uppercase">
                  <a href="#contact">Contact</a>
                </li>
                <hr />
                <li className="px-4 py-2 text-black hover:bg-gray-100 cursor-pointer uppercase">
                  <a href="/signin">sign In</a>
                </li>
              </ul>
            </nav>
          </div>
        </Transition>

        {/* Button to open menu */}
        <div className="fixed inset-y-0  right-0 flex items-start flex md:hidden">
          <button
            className="text-gray-500 focus:outline-none p-4"
            onClick={() => setIsOpen(true)}
          >
            <img src={menuicon} alt="" className="mb-1" />
            <img src={menuicon} alt="" className="mb-1" />
            <img src={menuicon} alt="" />
            {/* <svg
              className="w-6 h-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4 6h16M4 12h16m-7 6h7"
              />
            </svg> */}
          </button>
        </div>
      </div>
    </nav>
  );
};
