import React, { useEffect, useState } from "react";
import bluelock from "../images/blue lock.png";
import { useSelector } from "react-redux";
import { userData } from "../store/user/selectors";
import moment from "moment";
import { Box, CircularProgress } from "@mui/material";
import styled from "@emotion/styled";

export const Myaccount = () => {
  const [showModal, setShowModal] = useState(false);

  const userDataFromStorage = localStorage.getItem("userData");

  const userData = JSON.parse(userDataFromStorage);

  const handleLogout = (e) => {
    setShowModal(true);
  };
  const handleLogout2 = (e) => {
    setShowModal(false);
  };
  const handleLogout3 = (e) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${userData?.token}`);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: "", // Empty body as per your provided code
      redirect: "follow",
    };

    fetch(
      "https://api.ayyawinstudyfoundation.org/api/admin/signout",
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        console.log(result);
        // Clear user data from local storage and close the modal
        localStorage.removeItem("userData");
        setShowModal(false);
        // Optionally, you can redirect the user to the login page
        window.location.href = "/";
      })
      .catch((error) => {
        console.error("Error:", error);
        // Optionally, display an error message to the user
      });
  };

  const [id, setId] = useState(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [idcard, setIdCard] = useState("");
  const [city, setCity] = useState("");
  const [district, setDistrict] = useState("");
  const [state, setState] = useState("");
  const [institute, setInstitute] = useState("");
  const [type, setType] = useState("");
  const [university, setUniversity] = useState("");
  const [education, setEducation] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");
  const [verified, setVerified] = useState(null);
  const [createdAt, setCreatedAt] = useState("");
  const [updatedAt, setUpdatedAt] = useState("");
  const [disatched, setDispatched] = useState(false);

  const [kitStatus, setKitStatus] = useState("");
  const [kitCourier, setKitCourier] = useState("");
  const [kitImageUrl, setKitImageUrl] = useState("");
  const [kitDescription, setKitDescription] = useState("");
  const [kitReceivedDate, setKitReceivedDate] = useState("");
  const [kitDispatchedDate, setKitDispatchedDate] = useState("");
  const [kitReferenceNumber, setKitReferenceNumber] = useState("");

  // const userDataFromStorage = localStorage.getItem('userData');

  // const userData = JSON.parse(userDataFromStorage);

  // Destructuring user data if it exists
  // const name = userData?.name;
  // const email = userData?.email;
  // const mobile = userData?.mobile;
  // const city = userData?.city;
  // const district = userData?.district;
  // const state = userData?.state;
  // const university = userData?.university;
  // const type = userData?.type;
  // const address1 = userData?.address1;
  // const address2 = userData?.address2;
  // const mobile = userData?.mobile;
  // const mobile = userData?.mobile;

  console.log("Name:", name);
  console.log("Email:", email);
  console.log("mobile:", mobile);
  console.log("city:", city);
  console.log("district:", district);
  console.log("state:", state);
  console.log("idcard:", idcard);
  console.log("type:", address1);
  console.log("type:", address2);
  // console.log("userData:", userData);
  const FullScreenOverlay = styled(Box)(({ theme }) => ({
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    zIndex: 1300, // Higher than most other elements
  }));

  useEffect(() => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${userData?.token}`);

    const formdata = new FormData();
    formdata.append("orderid", "123");

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(
      "https://api.ayyawinstudyfoundation.org/api/myaccount",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log("result?.data", result?.data); // Log the data to inspect it
        const data = result.data;
        setId(data?.id);
        setName(data.name);
        setEmail(data.email);
        setMobile(data.mobile);
        setAddress1(data.address1);
        setAddress2(data.address2);
        setDispatched(data.dispatched);
        setIdCard(data.id_card_photo);
        setCity(data.city);
        setDistrict(data.district);
        setState(data.state);
        setType(data.type);
        setInstitute(data.institute);
        setUniversity(data.university);
        setEducation(data.education);
        setPaymentStatus(data.payment_status);
        setVerified(data.verified);
        setCreatedAt(data.created_at);
        setUpdatedAt(data.updated_at);

        const kit = JSON.parse(data?.kit || "{}");
        setKitStatus(kit.status);
        setKitCourier(kit.courier);
        setKitImageUrl(kit.image_url);
        setKitDescription(kit.description);
        setKitReceivedDate(kit.received_date);
        setKitDispatchedDate(kit.dispatched_date);
        setKitReferenceNumber(kit.reference_number);
      })
      .catch((error) => console.error("Error:", error));
  }, []);

  console.log(paymentStatus);
  console.log(id);
  console.log(name);

  const [selectedFile, setSelectedFile] = useState(null);

  const [file, setFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState("");

  useEffect(() => {
    // Set the initial preview URL from the API
    setPreviewUrl("id_card_photos/030624040636.png");
  }, []);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  const formattedDate = moment(kitDispatchedDate).format("YYYY-MM-DD HH:mm:ss");

  const [kitimage, setKitImage] = useState(null);
  const [reciveddate, setRecivedDate] = useState("");
  const [confirmtext, setConfirmtext] = useState("");
  const [validationErrors, setValidationErrors] = useState({});
  const [showform, setShowform] = useState(false);
  const [showForm1Loader, setShowForm1Loader] = useState(false);
  const validateForm = () => {
    let errors = {};
    let formIsValid = true;

    // Validate image
    if (!kitimage || !kitimage.type.startsWith("image/")) {
      errors.image = "Please select a valid image file.";
      formIsValid = false;
    }

    // Validate received date format
    const dateRegex = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/;
    if (!reciveddate.match(dateRegex)) {
      errors.received_date =
        "Please enter a valid date and time in the format YYYY-MM-DD HH:mm:ss (e.g., 2024-06-25 14:30:00).";
      formIsValid = false;
    }

    setValidationErrors(errors);

    return formIsValid;
  };

  const [formopen, setFormopen] = useState(true);

  const kitSubmit = () => {
    if (!validateForm()) {
      return;
    }
    setShowForm1Loader(true);

    // Format received date to match Y-m-d H:i:s
    const formattedDate = new Date(reciveddate)
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");

    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${userData?.token}`);

    const formdata = new FormData();
    formdata.append("image", kitimage);
    formdata.append("received_date", formattedDate);
    formdata.append("description", confirmtext);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(
      "https://api.ayyawinstudyfoundation.org/api/share-kit-image",
      requestOptions
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json(); // assuming response is JSON
      })
      .then((data) => {
        // Check for success based on your API response structure
        if (data && data.success) {
          setShowform(true);
          console.log(data); // Handle successful response
        } else {
          setShowform(false);
          console.error("Submission failed:", data); // Log error or handle as needed
        }
      })
      .catch((error) => {
        console.error("Fetch error:", error); // Handle fetch errors
      })
      .finally(() => {
        setShowForm1Loader(false); // Hide loader regardless of success or failure
      });
  };

  return (
    <>
      <div hidden={!showForm1Loader}>
        <div className="loader"></div>
        <FullScreenOverlay>
          <CircularProgress />
        </FullScreenOverlay>
      </div>

      <div className="container mx-auto  block md:flex">
        <div className="p-6 myaccount w-full md:w-3/6">
          {" "}
          <h3 className="mb-2">Your Profile</h3>
          <img
            className="mb-3"
            width={"50%"}
            style={{ borderRadius: "100%", height: "125px", width: "125px" }}
            src={`https://api.ayyawinstudyfoundation.org/${idcard}`}
          ></img>
          <div className="mb-2">
            <label htmlFor="name" className="block font-bold text-black mb-2 ">
              Name
            </label>
            <input
              id="name"
              type="text"
              placeholder="Enter your name"
              className="input-field"
              name="name"
              value={name}
            />
          </div>
          <div className="mb-2">
            <label htmlFor="name" className="block font-bold text-black mb-2">
              Email Address
            </label>
            <input
              id="email"
              type="email"
              placeholder="example@gmail.com"
              className="input-field"
              name="email"
              value={email}
            />
          </div>
          <div className="mb-2">
            <label htmlFor="name" className="block font-bold text-black mb-2">
              Mobile Number
            </label>
            <input
              id="number"
              type="number"
              placeholder="Enter your Number"
              className="input-field"
              name={mobile}
              value={mobile}
            />
          </div>
          <div className="mb-2">
            <label htmlFor="name" className="block font-bold text-black mb-2">
              Type
            </label>
            <select id="type" value={type} name="type" className="input-field">
              <option value="">Select Type</option>
              <option value="1">Student</option>
              <option value="2">Individual</option>
            </select>
          </div>
          <div className="mb-2">
            <label
              htmlFor="Address1"
              className="block font-bold text-black mb-2"
            >
              Address 1
            </label>
            <input
              name="Address1"
              id="Address1"
              type="text"
              placeholder="Apartment, Unit, Building, Floor, etc.. "
              className="input-field"
              value={address1}
            />
          </div>
          <div className="mb-2">
            <label
              htmlFor="Address2"
              className="block font-bold text-black mb-2"
            >
              Address 2
            </label>
            <input
              name="Address2"
              id="Address2"
              type="text"
              placeholder="Street Address, Area , P.O."
              className="input-field"
              value={address2}
            />
          </div>
          <div className="mb-2">
            <label htmlFor="city" className="block font-bold text-black mb-2">
              City
            </label>
            <input
              name="city"
              id="city"
              type="text"
              placeholder="Enter your city"
              className="input-field"
              value={city}
            />
          </div>
          <div className="mb-2">
            <label
              htmlFor="district"
              className="block font-bold text-black mb-2"
            >
              District
            </label>
            <input
              name="district"
              id="district"
              type="text"
              placeholder="Enter your district"
              className="input-field"
              value={district}
            />
          </div>
          <div className="mb-2">
            <label htmlFor="type" className="block font-bold text-black mb-2">
              State
            </label>
            <select
              id="state"
              name="state"
              value={state}
              className="input-field"
            >
              <option value="">Select state</option>
              <option value="Andra Pradesh">Andra Pradesh</option>
              <option value="Arunachal Pradesh">Arunachal Pradesh</option>
              <option value="Assam">Assam</option>
              <option value="Bihar">Bihar</option>
              <option value="Chhattisgarh">Chhattisgarh</option>
              <option value="Goa">Goa</option>
              <option value="Gujarat">Gujarat</option>
              <option value="Haryana">Haryana</option>
              <option value="Himachal Pradesh">Himachal Pradesh</option>
              <option value="Jammu and Kashmir">Jammu and Kashmir</option>
              <option value="Jharkhand">Jharkhand</option>
              <option value="Karnataka">Karnataka</option>
              <option value="Kerala">Kerala</option>
              <option value="Madya Pradesh">Madya Pradesh</option>
              <option value="Maharashtra">Maharashtra</option>
              <option value="Manipur">Manipur</option>
              <option value="Meghalaya">Meghalaya</option>
              <option value="Mizoram">Mizoram</option>
              <option value="Nagaland">Nagaland</option>
              <option value="Orissa">Orissa</option>
              <option value="Punjab">Punjab</option>
              <option value="Rajasthan">Rajasthan</option>
              <option value="Sikkim">Sikkim</option>
              <option value="Tamil Nadu">Tamil Nadu</option>
              <option value="Telangana">Telangana</option>
              <option value="Tripura">Tripura</option>
              <option value="Uttaranchal">Uttaranchal</option>
              <option value="Uttar Pradesh">Uttar Pradesh</option>
              <option value="West Bengal">West Bengal</option>
              <option
                disabled
                style={{ backgroundColor: "#aaa", color: "#fff" }}
              >
                UNION Territories
              </option>
              <option value="Andaman and Nicobar Islands">
                Andaman and Nicobar Islands
              </option>
              <option value="Chandigarh">Chandigarh</option>
              <option value="Dadar and Nagar Haveli">
                Dadar and Nagar Haveli
              </option>
              <option value="Daman and Diu">Daman and Diu</option>
              <option value="Delhi">Delhi</option>
              <option value="Lakshadeep">Lakshadeep</option>
              <option value="Pondicherry">Pondicherry</option>
            </select>
          </div>
          <div className="mb-2">
            <label htmlFor="name" className="block font-bold text-black mb-2">
              School / College / Institute Name
            </label>
            <input
              id="School"
              type="text"
              placeholder="Madurai Hr, Sec, School"
              className="input-field"
              name="School"
              value={university}
            />
          </div>
          <button className="bg-blue-600 mt-6 input-button hover:bg-blue-700 text-white font-bold py-4 px-8 w-full mb-8 focus:outline-none focus:shadow-outline">
            Save & Update
          </button>
          <button
            onClick={handleLogout}
            className="bg-red-600 mt-1 input-button hover:bg-red-700 text-white font-bold py-4 px-8 w-full mb-8 focus:outline-none focus:shadow-outline"
          >
            Log Out
          </button>
          {/* <form action="">
          <h3>Change Your Password</h3>
            <div className="mb-2">
              <label htmlFor="name" className="block font-bold text-black mb-2">
                Password
              </label>
              <input
                id="password"
                type="password"
                placeholder="password"
                className="input-field"
                name="password"
                autoComplete="new-password"
              />
            </div>
            <div className="mb-2">
              <label htmlFor="name" className="block font-bold text-black mb-2">
                Confirm Password
              </label>
              <input
                id="cpassword"
                type="password"
                placeholder="Confirm password"
                className="input-field"
                name="cpassword"
                autoComplete="new-password"
              />

            </div>
          </form>
          <button
            className="bg-green-600 mt-1 input-button hover:bg-green-700 text-white font-bold py-4 px-8 w-full mb-8 focus:outline-none focus:shadow-outline"  >
            Update Password
          </button> */}
          {showModal && (
            <div className="fixed top-0 left-0 w-full  h-full flex items-center justify-center bg-gray-500 bg-opacity-50 z-50">
              <div className="bg-white mx-5 p-8 rounded-lg shadow-lg logoutpopup">
                <h6>Log Out</h6>
                <p>
                  Are you sure you want to log out? You'll need to login again
                  to use the app.
                </p>
                <div className="flex justify-between logoutbtns">
                  <button onClick={handleLogout2}> Cancel</button>
                  <button onClick={handleLogout3}>Log Out</button>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="p-6 myaccount  w-full md:w-3/6">
          <h3 className="mb-6">Your Kit Details</h3>
          {disatched ? (
            <>
              <div className="kitinformation p-4 bg-gray-100 rounded-md shadow-md">
                <p className="font-semibold text-lg text-blue-600">
                  Your Kit status :{" "}
                  <span className="text-gray-800">{kitStatus}</span>
                </p>
                <p className="text-gray-700 py-4">
                  Courier → <span className="text-gray-800">{kitCourier}</span>
                </p>
                <p className="text-gray-700 pb-4">
                  Dispatched Date & Time →{" "}
                  <span className="text-gray-800">{formattedDate}</span>
                </p>
                <p className="text-gray-700 pb-4">
                  Reference Number →{" "}
                  <span className="text-gray-800">{kitReferenceNumber}</span>
                </p>
              </div>
              <div className=" myaccount">
                {/* Display validation errors */}
                {Object.keys(validationErrors).length > 0 && (
                  <div className="mb-4 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">
                    <strong className="font-bold">Validation errors:</strong>
                    <ul>
                      {Object.values(validationErrors).map((error, index) => (
                        <li key={index}>{error}</li>
                      ))}
                    </ul>
                  </div>
                )}

                {/* Form to upload kit image and submit */}
                {kitReceivedDate ? (
                  <>
                    <div className="kitinformation p-4 bg-gray-100 rounded-md shadow-md mt-5">
                      <div className="flex flex-col items-center">
                        <div className="checkmark-container mb-4">
                          <svg
                            className="checkmark h-12 w-12 text-green-500 animate-checkmark"
                            viewBox="0 0 52 52"
                          >
                            <circle
                              className="checkmark-circle"
                              cx="26" 
                              cy="26"
                              r="23"
                              fill="none"
                            />
                            <path
                              className="checkmark-check"
                              fill="none"
                              d="M14 27l7 7 16-16"
                            />
                          </svg>
                        </div>
                        <p className="font-semibold text-lg text-blue-600 text-center">
                          You have attached the Details successfully.
                        </p>
                      </div>
                    </div>

                    <style jsx>{`
                      @keyframes stroke {
                        0% {
                          stroke-dasharray: 0, 100;
                        }
                        100% {
                          stroke-dasharray: 100, 0;
                        }
                      }

                      @keyframes scale {
                        0%,
                        100% {
                          transform: none;
                        }
                        50% {
                          transform: scale3d(1.1, 1.1, 1.1);
                        }
                      }

                      .checkmark-container {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                      }

                      .checkmark-circle {
                        stroke: #34d399;
                        stroke-width: 4;
                      }

                      .checkmark-check {
                        stroke: #34d399;
                        stroke-width: 4;
                        stroke-linecap: round;
                        stroke-linejoin: round;
                        stroke-dasharray: 100;
                        stroke-dashoffset: 0;
                        animation: stroke 0.5s cubic-bezier(0.65, 0, 0.45, 1)
                            forwards,
                          scale 0.3s ease-in-out 0.5s forwards;
                      }
                    `}</style>
                  </>
                ) : showform ? (
                  <>
                    <div className="kitinformation p-4 bg-gray-100 rounded-md shadow-md mt-5">
                      <div className="flex flex-col items-center">
                        <div className="checkmark-container mb-4">
                          <svg
                            className="checkmark h-12 w-12 text-green-500 animate-checkmark"
                            viewBox="0 0 52 52"
                          >
                            <circle
                              className="checkmark-circle"
                              cx="26"
                              cy="26"
                              r="23"
                              fill="none"
                            />
                            <path
                              className="checkmark-check"
                              fill="none"
                              d="M14 27l7 7 16-16"
                            />
                          </svg>
                        </div>
                        <p className="font-semibold text-lg text-blue-600 text-center">
                          You have attached the Details successfully.
                        </p>
                      </div>
                    </div>

                    <style jsx>{`
                      @keyframes stroke {
                        0% {
                          stroke-dasharray: 0, 100;
                        }
                        100% {
                          stroke-dasharray: 100, 0;
                        }
                      }

                      @keyframes scale {
                        0%,
                        100% {
                          transform: none;
                        }
                        50% {
                          transform: scale3d(1.1, 1.1, 1.1);
                        }
                      }

                      .checkmark-container {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                      }

                      .checkmark-circle {
                        stroke: #34d399;
                        stroke-width: 4;
                      }

                      .checkmark-check {
                        stroke: #34d399;
                        stroke-width: 4;
                        stroke-linecap: round;
                        stroke-linejoin: round;
                        stroke-dasharray: 100;
                        stroke-dashoffset: 0;
                        animation: stroke 0.5s cubic-bezier(0.65, 0, 0.45, 1)
                            forwards,
                          scale 0.3s ease-in-out 0.5s forwards;
                      }
                    `}</style>
                  </>
                ) : (
                  <div className="kitinformation p-4 bg-gray-100 rounded-md shadow-md mt-5">
                    <p className="font-semibold text-lg text-blue-600 mb-4">
                      Please attach the proof of received kit image.
                    </p>
                    <div className="mb-4">
                      <label
                        htmlFor="kitImage"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Upload Kit Image:
                      </label>
                      <input
                        type="file"
                        id="kitImage"
                        name="kitImage"
                        multiple
                        className="mb-4 s block w-full text-sm mt-5 text-gray-500
                   file:mr-4 file:py-2 file:px-4
                   file:rounded-full file:border-0
                   file:text-sm file:font-semibold
                   file:bg-violet-50 file:text-violet-700
                   hover:file:bg-violet-100"
                        onChange={(e) => setKitImage(e.target.files[0])}
                      />
                      {validationErrors.image && (
                        <p className="text-red-500 text-xs mt-1">
                          {validationErrors.image}
                        </p>
                      )}
                    </div>
                    <div className="mb-4">
                      <label
                        htmlFor="dateTime"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Date and Time:
                      </label>
                      <input
                        type="datetime-local"
                        id="dateTime"
                        name="dateTime"
                        className={`mt-1 py-2 px-3 block w-full bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm ${
                          validationErrors.received_date ? "border-red-500" : ""
                        }`}
                        onChange={(e) => {
                          const dateValue = e.target.value; // This gives you YYYY-MM-DDTHH:mm format
                          const formattedDate =
                            dateValue.replace("T", " ") + ":00"; // Add seconds if needed
                          setRecivedDate(formattedDate); // Set state with formatted date
                        }}
                      />
                      {validationErrors.received_date && (
                        <p className="text-red-500 text-xs mt-1">
                          {validationErrors.received_date}
                        </p>
                      )}
                    </div>
                    <div className="mb-4">
                      <label
                        htmlFor="additionalInfo"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Additional Information:
                      </label>
                      <textarea
                        id="additionalInfo"
                        name="additionalInfo"
                        rows="3"
                        className="mt-1 py-2 px-3 block w-full bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        onChange={(e) => setConfirmtext(e.target.value)}
                      ></textarea>
                    </div>
                    <button
                      type="button"
                      onClick={kitSubmit}
                      className="inline-block px-4 py-2 bg-blue-600 text-white rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                    >
                      Submit
                    </button>
                  </div>
                )}
              </div>
            </>
          ) : (
            <>
              <p className="text-gray-500 text-sm">
                Your kit is being prepared for dispatch; tracking information
                will be sent to you soon.
              </p>
            </>
          )}
        </div>
      </div>
    </>
  );
};
