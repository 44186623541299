import React from "react";
import { Footermain } from "./Footermain";

export const ShippingandRefund = () => {
  return (
    <>
      <div className="container mx-auto text-center PrivacyandPolicy">
        <div>Shipping and Refund</div>
      </div>
      <div className="container mx-auto pandpcontent p-5">
        <h5>Shipping & Delivery Information</h5>
        <p>
          Most of Our kit After 90 days, the kit is delivered in a minimum of 10
          days. <br />
          <p className="pb-5"></p>
          * The kit inside Practice manual on daily activities. <br />
          * Tree seeds and nursery cover. <br />
          * Package of small grain food products worth Rs.260.
          <br />
          * Home Garden Equipment Kit and Manual.
          <br />* Participant certificate and medal ​​.
          <p className="pb-1"></p>
          <br /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Will be sent on
          completion of 90 days.
        </p>
        <h5>Return and Refund Policy</h5>
        <p>
          Thank you for choosing us. Please note, all sales are final. We do not
          offer refunds or returns, except for damaged or defective items. We
          encourage thorough review before purchase. For assistance, contact us
          anytime.
        </p>
      </div>
      <Footermain />
    </>
  );
};
