import React, { useCallback, useEffect, useRef, useState } from "react";
import righttick from "../images/righttick.png";
import { AK } from "../constants/AppKeys";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import axios from "axios";
// Redux Selector / Action
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../store/user/actions";
export const Signin = () => {
  let dispatch = useDispatch();
  const [showAdditionalFields, setShowAdditionalFields] = useState(false);
  const [showForm1, setShowForm1] = useState(false);
  const [showForm2, setShowForm2] = useState(false);
  const [showForm3, setShowForm3] = useState(false);
  const [showForm4, setShowForm4] = useState(false);
  const [showForm5, setShowForm5] = useState(false);
  const [showForm6, setShowForm6] = useState(false);
  const [showForm7, setShowForm7] = useState(false);
  const [showForm8, setShowForm8] = useState(false);

  const [showForm1Loader, setShowForm1Loader] = useState(false);
  const [showForm2Loader, setShowForm2Loader] = useState(false);
  const [showForm3Loader, setShowForm3Loader] = useState(false);
  const [showForm4Loader, setShowForm4Loader] = useState(false);
  const [showForm5Loader, setShowForm5Loader] = useState(false);
  const [showForm6Loader, setShowForm6Loader] = useState(false);
  const [showForm7Loader, setShowForm7Loader] = useState(false);
  const [showForm8Loader, setShowForm8Loader] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [formData, setFormData] = useState(new FormData());
  const [formdata, setFormdata] = useState(new FormData());
  const [fieldErrors, setFieldErrors] = useState({});
  const [pageinit, setPageInit] = useState(false);
  const [usertoken, setusertoken] = useState("");
  const [count, setCount] = useState(1);

  useEffect(() => {
    console.log("***");
  }, [count]);

  const FullScreenOverlay = styled(Box)(({ theme }) => ({
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    zIndex: 1300, // Higher than most other elements
  }));

  const [sfD, setsfD] = useState({
    mobile: "",
    confirmationcode: "",
    name: "",
    email: "",
    type: "",
    city: "",
    district: "",
    state: "",
    postalcode: "",
    schoolcollegeinstitute: "",
    idcard: null,
    digitcode: "",
    id_card_photo: "",

    digit1: "",
    digit2: "",
    digit3: "",
    digit4: "",
    digit5: "",
    digit6: "",

    mobileError: false,
    confirmationcodeError: false,
    nameError: false,
    emailError: false,
    typeError: false,
    cityError: false,
    districtError: false,
    stateError: false,
    postalcodeError: false,
    schoolcollegeinstituteError: false,
    idcardError: false,
    digitcodeError: false,
    id_card_photoError: false,

    mobileErrorMsg: "",
    confirmationcodeErrorMsg: "",
    nameErrorMsg: "",
    emailErrorMsg: "",
    typeErrorMsg: "",
    cityErrorMsg: "",
    districtErrorMsg: "",
    stateErrorMsg: "",
    postalcodeErrorMsg: "",
    schoolcollegeinstituteErrorMsg: "",
    idcardErrorMsg: "",
    digitcodeErrorMsg: "",
    id_card_photoErrorMsg: "",
  });

  const reqeustOTP = async (e) => {
    try {
      const formData = new FormData();
      formData.append("mobile", sfD.mobile);

      return await axios
        .post(AK.APIURL + AK.SIGNINAPI, formData)
        .then((response) => {
          console.log(response);
          return true;
        });
    } catch (error) {
      console.log(error);
      changeInput(error?.response?.data?.message, "mobileErrorMsg");
      changeInput(true, "mobileError");
      console.error(error);
    }
    return false;
  };

  const submitOTP = async (e) => {
    try {
      const formData = new FormData();
      formData.append("mobile", sfD.mobile);
      formData.append(
        "otp",
        sfD.digit1 +
          sfD.digit2 +
          sfD.digit3 +
          sfD.digit4 +
          sfD.digit5 +
          sfD.digit6
      );

      return await axios
        .post(AK.APIURL + AK.SIGNINOTPAPI, formData)
        .then((response) => {
          let userdata = JSON.parse(JSON.stringify(response.data.data));
          console.log("userdata", userdata);
          dispatch(setUser(userdata));
          localStorage.setItem("userData", JSON.stringify(userdata));
          console.log("***userdata***");
          console.log(userdata);
          setusertoken(userData?.token);
          return true;
        });
    } catch (error) {
      changeInput(error?.response?.data?.message, "mobileErrorMsg");
      changeInput(true, "mobileError");
      console.error(error);
    }
    return false;
  };

  const submitForm = async (e) => {
    try {
      const formData = new FormData();
      formData.append("type", sfD.type);
      formData.append("university", sfD.schoolcollegeinstitute);
      formData.append("city", sfD.city);
      formData.append("district", sfD.district);
      formData.append("state", sfD.state);
      formData.append("education", sfD.education);
      formData.append("name", sfD.name);
      formData.append("mobile", sfD.mobile);
      formData.append("email", sfD.email);
      formData.append("institute", sfD.institute);
      let id_card_photoFile = document.querySelector("#id_card_photo");
      if (id_card_photoFile && id_card_photoFile.files[0] !== null) {
        formData.append("id_card_photo", id_card_photoFile.files[0]);
      }

      return await axios
        .post(AK.APIURL + AK.SUBMITSIGNUPAPI, formData)
        .then((response) => {
          console.log(response);
          return true;
        });
    } catch (error) {
      changeInput(error?.response?.data?.message, "id_card_photoMsg");
      changeInput(true, "id_card_photoError");
      console.error(error);
    }
    return false;
  };

  // const nameValue = document.getElementById("name").value;
  // const mobileValue = document.getElementById("mobile").value;
  // const emailValue = document.getElementById("email").value;

  const [fileInput, setFileInput] = useState(null);

  const handleSubmit2 = async (event) => {
    event.preventDefault();

    // Check if file input is empty or not selected
    if (!fileInput) {
      console.error("No file selected");
      return;
    }

    formdata.append("step", "2");
    formdata.append("type", "1");
    formdata.append("university", "Anna University");
    formdata.append("city", "Chennai");
    formdata.append("district", "Chennai");
    formdata.append("state", "TamilNadu");
    formdata.append("education", "BCA");

    const requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        "https://api.ayyawinstudyfoundation.org/api/register",
        requestOptions
      );

      // Check if response status is 200
      if (response.status === 200) {
        setShowAdditionalFields(true);
      } else {
        console.error("Failed with status:", response.status);
      }

      const result = await response.text();
      console.log(result);
    } catch (error) {
      console.error(error);
    }
  };

  const [currentTime, setCurrentTime] = useState(new Date());
  const [redirectingsec, setredirectingsec] = useState(3);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(intervalId); // Cleanup function to prevent memory leaks
  }, []);

  const getTimeRemaining = () => {
    const totalTime = currentTime;
    const seconds = Math.floor((totalTime / 1000) % 60);
    const minutes = Math.floor((totalTime / (1000 * 60)) % 60);
    const hours = Math.floor((totalTime / (1000 * 60 * 60)) % 24);
    const days = Math.floor(totalTime / (1000 * 60 * 60 * 24));

    return {
      days,
      hours,
      minutes,
      seconds,
    };
  };

  const { days, hours, minutes, seconds } = getTimeRemaining();

  const handleChange = (e) => {
    const { name, value } = e.target;
    formData.append(name, value);
    // Clear error message for the field when user starts typing again
    if (value === "")
      setFieldErrors({
        ...fieldErrors,
        [name]: "Please fill this " + name + " field.",
      });
    else
      setFieldErrors({
        ...fieldErrors,
        [name]: "",
      });
  };

  const allhideForm = () => {
    setShowForm8(false);
    setShowForm7(false);
    setShowForm6(false);
    setShowForm5(false);
    setShowForm4(false);
    setShowForm3(false);
    setShowForm2(false);
    setShowForm1(false);
  };

  const handleSubmitForm = async (e, formname) => {
    e.preventDefault();

    // mobile: "",
    // confirmationcode: "",
    // name: "",
    // email: "",
    // type: "",
    // city: "",
    // district: "",
    // state: "",
    // postalcode: "",
    // schoolcollegeinstitute: "",
    // idcard: null,
    // digitcode: "",

    changeInput("", "mobileErrorMsg");
    changeInput("", "nameErrorMsg");
    changeInput("", "emailErrorMsg");
    changeInput("", "typeErrorMsg");
    changeInput("", "cityErrorMsg");
    changeInput("", "districtErrorMsg");
    changeInput("", "stateErrorMsg");
    changeInput("", "postalcodeErrorMsg");
    changeInput("", "schoolcollegeinstituteErrorMsg");

    changeInput(false, "mobileError");
    changeInput(false, "nameError");
    changeInput(false, "emailError");
    changeInput(false, "typeError");
    changeInput(false, "cityError");
    changeInput(false, "districtError");
    changeInput(false, "stateError");
    changeInput(false, "postalcodeError");
    changeInput(false, "schoolcollegeinstituteError");

    let error = false;
    if (formname == "Form1") {
      if (sfD.mobile === "") {
        changeInput(true, "mobileError");
        changeInput("Please enter valid mobile number.", "mobileErrorMsg");
        error = true;
      }

      if (sfD.mobile.length != 10) {
        changeInput(true, "mobileError");
        changeInput("Please enter valid mobile number.", "mobileErrorMsg");
        error = true;
      }
    }

    if (formname == "Form3") {
      if (sfD.name === "") {
        changeInput(true, "nameError");
        changeInput("Please enter valid name.", "nameErrorMsg");
        error = true;
      }

      if (sfD.email === "") {
        changeInput(true, "emailError");
        changeInput("Please enter valid email.", "emailErrorMsg");
        error = true;
      }

      if (sfD.type === "") {
        changeInput(true, "typeError");
        changeInput("Please enter valid type.", "typeErrorMsg");
        error = true;
      }
    }

    if (formname == "Form4") {
      if (sfD.city === "") {
        changeInput(true, "cityError");
        changeInput("Please enter valid city.", "cityErrorMsg");
        error = true;
      }

      if (sfD.district === "") {
        changeInput(true, "districtError");
        changeInput("Please enter valid district.", "districtErrorMsg");
        error = true;
      }

      if (sfD.state === "") {
        changeInput(true, "stateError");
        changeInput("Please enter valid district.", "stateErrorMsg");
        error = true;
      }

      if (sfD.postalcode === "") {
        changeInput(true, "postalcodeError");
        changeInput("Please enter valid district.", "postalcodeErrorMsg");
        error = true;
      }
    }

    if (formname == "Form5") {
      if (sfD.schoolcollegeinstitute === "") {
        changeInput(true, "schoolcollegeinstituteError");
        changeInput(
          "Please enter valid School College Institute.",
          "schoolcollegeinstituteErrorMsg"
        );
        error = true;
      }

      let id_card_photoFile = document.querySelector("#id_card_photo");
      console.log(id_card_photoFile);
      if (id_card_photoFile && id_card_photoFile.files[0] !== null) {
        // formData.append("id_card_photoFile", id_card_photoFile.files[0]);
      } else {
        changeInput(true, "id_card_photoError");
        changeInput("Please upload a valid file.", "id_card_photoErrorMsg");
        error = true;
      }
    }

    if (error) {
      return;
    }

    if (formname == "Form1") {
      setShowForm1Loader(true);
      if (await reqeustOTP()) {
        allhideForm();
        setShowForm2(true);
      }
      setShowForm1Loader(false);
    } else if (formname == "Form2") {
      setShowForm2Loader(true);
      if (await submitOTP()) {
        allhideForm();
        setShowForm3(true);
        setTimeout(() => {
          window.location.href = "/mytask";
        }, 3000);
      }
      setShowForm2Loader(false);
    } else if (formname == "Form3") {
      allhideForm();
      setShowForm4(true);
    } else if (formname == "Form4") {
      allhideForm();
      setShowForm5(true);
    } else if (formname == "Form5") {
      setShowForm5Loader(true);
      if (await submitForm()) {
        allhideForm();
        setShowForm6(true);
        setredirectingsec(3);

        for (var i = 3; i > 0; i--) {
          setInterval(async () => {
            setredirectingsec(i);
            setCount((count) => (count = count + 1));
            if (i === 0) {
              setShowForm7(true);
              setShowForm6(false);
            }
          }, 1000);
        }
      }

      setShowForm5Loader(false);
    } else if (formname == "Form6") {
      allhideForm();
      setShowForm8(true);
    } else if (formname == "Form7") {
      allhideForm();
      setShowForm8(true);
    }
  };

  const inputRefs = useRef(Array.from({ length: 6 }, () => React.createRef()));

  const changeInput = useCallback((value, field, inputIndex,index) => {
    if (field === "mobile") {
      if (value?.length > 10) {
        setsfD({ ...sfD, mobileErrorMsg: "Mobile number must be 10 digits" });
      } else {
        setsfD({ ...sfD, mobileErrorMsg: "" });
      }
    }

 if (value === '' && inputIndex > 0) {
      // Move focus to the previous input if value is empty
      handleFocus(inputIndex - 1);
    } else if (value?.length > 0 && inputIndex < inputRefs.current.length - 1) {
      // Move focus to the next input if value length is greater than 0
      handleFocus(inputIndex + 1);
    }

    setsfD((prevState) => ({
      ...prevState,
      [field]: value,
    }));

    setCount((count) => count + 1);
  }, []);

  const userData = useSelector((state) => state.user.user.userData);
  const name = userData?.name;
  const email = userData?.email;
  if (!pageinit) {
    setPageInit(true);
    setShowForm1(true);
    setShowForm2(false);
    setShowForm3(false);
    setShowForm4(false);
    // Destructuring user data if it exists

    console.log("Name:", name);
    console.log("Email:", email);
    console.log("userData:", userData);
  }
  // localStorage.setItem('userData', JSON.stringify(userData));

  const handleLogout = () => {
    localStorage.removeItem("userData");
    // Dispatch action to clear user data from Redux state
  };
  const handleFocus = (index) => {
    if (inputRefs.current[index]?.current) {
      inputRefs.current[index].current.focus();
    }
  };

  const handleKeyDown = (e, index) => {
    const value = e.target.value;
    if (e.key === 'Backspace' && value === '') {
      // Move focus to the previous input if Backspace is pressed and input is empty
      if (index > 0) {
        handleFocus(index - 1);
      }
    } else if (e.key === 'ArrowRight' && value?.length > 0) {
      // Move focus to the next input if ArrowRight is pressed and input has value
      if (index < inputRefs.current.length - 1) {
        handleFocus(index + 1);
      }
    }
  };
  return (
    <div className="mt-2 pt-10 container mx-auto px-2 md:px-16 flex items-center justify-center bg-white">
      <div className="max-w-lg mt-3 md:mt-16 my-auto  mx-auto container w-full px-6 ">
        <div className="grid gap-6 ">
          <form>
            {showForm1 ? (
              <>
                <h2
                  className="text-4xl font-bold text-black mb-8"
                  style={{ display: "flex", fontFamily: "Montserrat" }}
                >
                  Sign In &nbsp;&nbsp;&nbsp;{" "}
                  <div hidden={!showForm1Loader}>
                    {/* <div className="loader"></div> */}
                    <FullScreenOverlay>
                      <CircularProgress />
                    </FullScreenOverlay>
                  </div>
                </h2>
                <p className=" mb-4" style={{ fontFamily: "Nunito Sans" }}>
                  Join our community of eco-minded students! Together, let's
                  plant trees and nurture a greener tomorrow. Make a positive
                  impact, gain environmental insights, and enjoy rewarding
                  activities.
                </p>
                <p style={{ fontFamily: "Nunito Sans" }} className="pb-5">
                  {" "}
                  Join us in safeguarding our planet!
                  <span className="wave">🌱</span> #Joinayyawinsfoundation
                </p>
                <div className="mb-2">
                  <label
                    htmlFor="mobile"
                    className="block font-bold text-black mb-2"
                  >
                    Mobile Number
                  </label>
                  <input
                     onChange={(e) => { const newValue = e.target.value.replace(/\D/g, '');
    changeInput(newValue, "mobile");
  }}
                    name="mobile"
                    id="mobile"
                    type="text"
                    placeholder="Enter your mobile number"
                    className="input-field"
                  />
                  <p className="text-red-500">{sfD.mobileErrorMsg}</p>
                </div>
                <button
                  onClick={(e) => handleSubmitForm(e, "Form1")}
                  className="bg-blue-500 mt-6 input-button hover:bg-blue-700 text-white font-bold py-4 px-8 w-full mb-8 focus:outline-none focus:shadow-outline"
                >
                  Next
                </button>
              </>
            ) : (
              <></>
            )}

            {showForm2 ? (
              <>
                {/* <h2
                  className="text-4xl font-bold text-black mb-8"
                  style={{ display: "flex" }}
                >
                  Sign In &nbsp;&nbsp;&nbsp;{" "}
                  <div hidden={!showForm1Loader}>
                    <div className="loader"></div>
                  </div>
                  <div hidden={!showForm2Loader}>
                    <div className="loader"></div>
                  </div>
                </h2>

                <p className="text-md mb-8">
Join our community of eco-minded students! Together, let's plant trees and nurture a greener tomorrow. Make a positive impact, gain environmental insights, and enjoy rewarding activities. Join us in safeguarding our planet! 🌱 #GreenGeneration</p> */}
                <div className="mt-2 pt-2 flex items-center justify-center bg-white">
                  <div className="max-w-md w-full px-6 loginpage">
                    <h2 className="text-2xl text-center font-bold text-black mb-8">
                      Enter confirmation code
                    </h2>
                    <p className="text-sm text-center text-gray-500 mb-8">
                      A 6-digit code was sent to {sfD.mobile}
                    </p>
                    <div className="mb-3 flex justify-center">
                      {/* OTP input fields */}
                      {Array.from({ length: 6 }, (_, index) => (
                        <input
                          key={index}
                          className="border border-gray-300 rounded-md px-4 py-2 text-center w-16 mr-2"
                          id={`digit${index + 1}`}
                          onChange={(e) =>
                            changeInput(
                              e.target.value,
                              `digit${index + 1}`,
                              index
                            )
                          }
                          onKeyDown={(e) => handleKeyDown(e, index)}
                          type="text"
                          maxLength="1"
                          placeholder="0"
                          ref={inputRefs.current[index]}
                        />
                      ))}
                    </div>
                    <center>
                      <p className="text-red-500">{sfD.mobileErrorMsg}</p>
                    </center>
                  
                    <button
                      onClick={(e) => handleSubmitForm(e, "Form2")}
                      className="bg-blue-500 mt-4 hover:bg-blue-700 text-white font-bold py-4 px-8 w-full focus:outline-none focus:shadow-outline"
                    >
                      Continue
                    </button>
                      <p
                      className="text-center text-blue-500 hover:text-blue-600 font-bold mt-6"
                      onClick={(e) => handleSubmitForm(e, "Form1")}
                      style={{ cursor: "pointer" }}
                    >
                      Resend code
                    </p>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}

            {showForm3 ? (
              <>
                <div className="succestick">
                  <img src={righttick} />
                  <h2 className="text-4xl text-center font-bold text-black mb-8">
                    Sign In Successful.
                  </h2>
                  <center>
                    {" "}
                    <p>Redirecting to home {redirectingsec} s.</p>
                  </center>
                </div>
              </>
            ) : (
              <></>
            )}
          </form>
        </div>
        <p className="text-black text-center text-md mt-4 mb-16">
          Join us to plant trees and connect for a greener future! 🌱🌍
          #Joinayyawinsfoundation ?
          <a
            href="/signup"
            className="text-blue-500 font-bold hover:text-blue-600"
          >
            &nbsp; Sign up
          </a>
        </p>
      </div>
    </div>
  );
};
