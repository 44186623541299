import React, { useEffect, useState } from 'react';
import father from "../images/father.png";
import mother from "../images/mother.png";
import kids from "../images/kids.png";
import { AK } from '../constants/AppKeys';
import ministar from "../images/Star Filled.png";
import styled from '@emotion/styled';
import axios, { Axios } from "axios";
import { Box, CircularProgress } from "@mui/material";
import star from "../images/Start.png";

export const PaidTaskPage = () => {
  const [selectedFiles, setSelectedFiles] = useState([]);

  const [fatherFiles, setFatherFiles] = useState([]);

  const [motherFiles, setMotherFiles] = useState([]);

  const [kidsFiles, setKidsFiles] = useState([]);

  const [error, setError] = useState('');

  const [showForm1Loader, setShowForm1Loader] = useState(false);

  const [familytasksuccessshowform, setFamilytasksuccessshowform] = useState(false);

  const [familytaskshowform, setFamilytaskshowform] = useState(false);

  const FullScreenOverlay = styled(Box)(({ theme }) => ({
    color:"green",
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    zIndex: 1300, // Higher than most other elements
  }));

  const handleFileChange = (event, task) => {
    const files = Array.from(event.target.files);
    if (task === 'father') {
      setFatherFiles((prevFiles) => [...prevFiles, ...files]);
    } else if (task === 'mother') {
      setMotherFiles((prevFiles) => [...prevFiles, ...files]);
    } else if (task === 'kids') {
      setKidsFiles((prevFiles) => [...prevFiles, ...files]);
    }
  };

  const removeFile = (index, task) => {
    if (task === 'father') {
      setFatherFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    } else if (task === 'mother') {
      setMotherFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    } else if (task === 'kids') {
      setKidsFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    }
  };

  const handlesubmit = (event) => {
    event.preventDefault();
    // Log all selected files in the console
    console.log('Submitted Files:', selectedFiles);
  };

  const userDataFromStorage = localStorage.getItem("userData");

  const userData = JSON.parse(userDataFromStorage);

  const UplooadTask = async (task, files) => {
    setShowForm1Loader(true);
    // Check for file validity and handle errors
    if (!files || files.length === 0) {
      setError("Please choose a file.");
      setShowForm1Loader(false); // Ensure loader is turned off if no files are chosen
      return;
    }
    
    // Create a FormData object
    const formData = new FormData();
    formData.append("caption", "We are Testing");
    
    // Append each file to FormData
    for (const file of files) {
      if (file instanceof File) {
        // Validate file type if necessary
        const validTypes = ['video/mp4', 'video/mpeg', 'video/quicktime'];
        if (!validTypes.includes(file.type)) {
          setError("Invalid file type. Allowed types are mp4, mpeg, quicktime.");
          setShowForm1Loader(false); // Ensure loader is turned off on invalid file type
          return;
        }
        
        formData.append('media', file);  // Append the actual file object
        console.log(file.name, "file");    // Log the file name for debugging
      } else {
        setError("One or more files are invalid.");
        setShowForm1Loader(false); // Ensure loader is turned off on invalid files
        return;
      }
    }
    
    formData.append("family_role", task);
    setShowForm1Loader(true); // Start the loader before the API call
  
    try {
      // Send the form data
      const response = await fetch("https://api.ayyawinstudyfoundation.org/api/paid/competition/submit/request", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${userData?.token}`,
        },
        body: formData,
      });
  
      // Check if the response is OK
      if (!response.ok) {
        const errorData = await response.json();
        setError(errorData.errors?.media?.[0] || errorData.message || 'An unexpected error occurred.');
      } else {
        const result = await response.text();
        console.log(result);
      }
      if (task === '1') {
        setFatherFiles([]);
      } else if (task === '2') {
        setMotherFiles([]);
      } else if (task === '3') {
        setKidsFiles([]);
      }
    } catch (error) {
      console.error('Error:', error);
      setError('An unexpected error occurred.');
      
    } finally {
      // Ensure the loader is turned off regardless of success or failure
      setShowForm1Loader(false);
    }
  };
  


  const payamount = async (e) => {
    setShowForm1Loader(true);
    try {
      var amount = 1500;
      let payload = {
        amount: amount,
      };

      const headers = {
        Authorization: `Bearer ${userData?.token}`,
        "Content-Type": "application/json",
      };

      return await axios
        .post(AK.APIURL + AK.HAODAPAYPAIDTASK, payload, {
          headers,
          maxContentLength: Infinity,
          maxBodyLength: Infinity,
        })
        .then(async (response) => {
          if (
            response != typeof undefined &&
            response.data != typeof undefined
          ) {
            if (response?.data?.data?.payment_link != "") {
              window.location.href = response?.data?.data?.payment_link;
            }
          }
          return true;
        });
    } catch (error) {
      console.error(error);
      setShowForm1Loader(false);
    }
    setShowForm1Loader(false);
    return false;
  };

  useEffect(() => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${userData?.token}`);

    // const urlencoded = new URLSearchParams();

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      // body: urlencoded,
      redirect: "follow"
    };

    fetch("https://api.ayyawinstudyfoundation.org/api/paid/competition/verify/status", requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);

        const result = data.paid_status;

        const taskstatus = data.task_status;

        setFamilytasksuccessshowform(result);

        setFamilytaskshowform(taskstatus);
      })
      .catch((error) => console.error('Error:', error));
  }, []); // Empty dependency array ensures this runs once on mount

  return (
    <div className='mt-5'>
      {familytaskshowform ? (<>
        {familytasksuccessshowform ? (<>
          {error && (
        <p className="bg-red-100 text-red-700  border border-red-400 p-3 rounded-md max-w-md mx-auto text-center opacity-90 animate-slideDown">
          {error}
        </p>
      )}
          <h3
            style={{ width: "fit-content" }}
            className="text-xl font-bold text-green-800 p-4 rounded-lg"
          >
            <span className="uppercase flex items-center">
              🎉 Welcome, {userData?.name}'s Family!
            </span>
            <br />
            <span className="uppercase flex items-center">
              Family Competitions!   <br />
              <br />
            </span>
            <span className="message">
              Foster a deeper connection within families by promoting healthy living
              and nurturing innovative thinking. Share your unique talent in a way
              that touches hearts, demonstrating how it can bring families closer
              together, inspire positive change, and enhance well-being.
            </span>
          </h3>

          <div className="container mx-auto ">
            <div className="flex flex-wrap ">
              <div className="w-full md:w-1/3 px-4 mb-4 md:mb-0">
                <div className="p-4 familytask">
                  <div className="flex items-center">
                    <img width={'40PX'} src={father} alt="Father's Task" />
                    <b className="ps-5">
                      Father's Task <br />
                      <span>Business Idea Presentation</span>
                    </b>
                  </div>
                  <div className="familytaskcontent pt-5">
                    <p>
                      Create a compelling video of your business idea and share it
                      with your audience. Wait for feedback and results, and focus
                      on fostering emotional connections and personal touches to
                      inspire and engage effectively.
                    </p>
                    <label htmlFor="fileInputFather" className="mt-2 block w-full">
                      <input
                        multiple
                        id="fileInputFather"
                        onChange={(e) => handleFileChange(e, 'father')}
                        type="file"
                        className="mb-4 block w-full text-sm mt-5 text-gray-500
                     file:mr-4 file:py-2 file:px-4
                     file:rounded-full file:border-0
                     file:text-sm file:font-semibold
                     file:bg-violet-50 file:text-violet-700
                     hover:file:bg-violet-100"
                      />
                    </label>
                    <button
                      onClick={() => UplooadTask('1', fatherFiles)}
                      className="bg-violet-800 mt-6 rounded-md hover:bg-violet-600 text-white font-bold py-4 px-8 w-full mb-8 focus:outline-none focus:shadow-outline"
                    >
          
                      Submit
                    </button>
                    <div className="mt-4">
                      {fatherFiles.map((file, index) => {
                        const fileURL = URL.createObjectURL(file);
                        const isVideo = file.type.startsWith('video/');
                        return (
                          <div key={index} className="relative inline-block mr-2">
                            {isVideo ? (
                              <video
                                controls
                                className="h-40 w-40 object-cover rounded"
                              >
                                <source src={fileURL} type={file.type} />
                                Your browser does not support the video tag.
                              </video>
                            ) : (<>
                              <img
                                src={fileURL}
                                alt="selected"
                                className="h-20 w-20 object-cover rounded"
                              />
                              <span style={{color:"red"}}>Image  File is Not <br/> Accepted please remove it </span>
                              </>
                            )}
                            <button
                              className="absolute top-0 right-0 bg-red-500 text-white rounded-full h-6 w-6 flex items-center justify-center"
                              onClick={() => removeFile(index, 'father')}
                            >
                              &times;
                            </button>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full md:w-1/3 px-4 mb-4 md:mb-0">
                <div className="p-4 familytask">
                  <div className="flex items-center">
                    <img width={'40PX'} src={mother} alt="Mother's Task" />
                    <b className="ps-5">
                      Mother's Task <br />
                      <span>Nutrition Food Preparation</span>
                    </b>
                  </div>
                  <div className="familytaskcontent pt-5">
                    <p>
                      Craft an engaging video that highlights your nutrition food
                      preparation techniques. Share it with your audience and give
                      them time to respond. Emphasize the benefits of your approach
                      and connect with viewers on a personal level to create a
                      lasting impact.
                    </p>
                    <label htmlFor="fileInputMother" className="mt-2 block w-full">
                      <input
                        multiple
                        id="fileInputMother"
                        onChange={(e) => handleFileChange(e, 'mother')}
                        type="file"
                        className="mb-4 block w-full text-sm mt-5 text-gray-500
                     file:mr-4 file:py-2 file:px-4
                     file:rounded-full file:border-0
                     file:text-sm file:font-semibold
                     file:bg-amber-100 file:text-amber-800
                     hover:file:bg-amber-100"
                      />
                    </label>
                    <button
                      onClick={() => UplooadTask('2', motherFiles)}

                      className="bg-amber-400 mt-6 rounded-md hover:bg-amber-600 text-white font-bold py-4 px-8 w-full mb-8 focus:outline-none focus:shadow-outline"
                    >
                      Submit
                    </button>
                    <div hidden={!showForm1Loader}>
            {/* <div className="loader"></div> */}
            <FullScreenOverlay>
              <CircularProgress style={{color:"green"}} />
            </FullScreenOverlay>
          </div>
                    <div className="mt-4">
                      {motherFiles.map((file, index) => {
                        const fileURL = URL.createObjectURL(file);
                        const isVideo = file.type.startsWith('video/');
                        return (
                          <div key={index} className="relative inline-block mr-2">
                            {isVideo ? (
                              <video
                                controls
                                className="h-40 w-40 object-cover rounded"
                              >
                                <source src={fileURL} type={file.type} />
                                Your browser does not support the video tag.
                              </video>
                            ) : (<>
                              <img
                                src={fileURL}
                                alt="selected"
                                className="h-20 w-20 object-cover rounded"
                              />
                              <span style={{color:"red"}}>Image  File is Not <br/> Accepted please remove it </span>
                              </>
                            )}
                            <button
                              className="absolute top-0 right-0 bg-red-500 text-white rounded-full h-6 w-6 flex items-center justify-center"
                              onClick={() => removeFile(index, 'mother')}
                            >
                              &times;
                            </button>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>

              <div className="w-full md:w-1/3 px-4 mb-4 md:mb-0">
                <div className="p-4 familytask">
                  <div className="flex items-center">
                    <img width={'40PX'} src={kids} alt="Kid's Task" />
                    <b className="ps-5">
                      Kid's Task <br />
                      <span>Individual Talent Showcase</span>
                    </b>
                  </div>
                  <div className="familytaskcontent pt-5">
                    <p>
                      Submit a 3-minute video highlighting your kid's unique
                      talents. Capture the essence of your skills and creativity,
                      and present them in a way that captivates and impresses your
                      audience. Ensure your video reflects your personal flair and
                      stands out.
                    </p>
                    <label htmlFor="fileInputKids" className="mt-2 block w-full">
                      <input
                        multiple
                        id="fileInputKids"
                        onChange={(e) => handleFileChange(e, 'kids')}
                        type="file"
                        className="mb-4 block w-full text-sm mt-5 text-gray-500
                     file:mr-4 file:py-2 file:px-4
                     file:rounded-full file:border-0
                     file:text-sm file:font-semibold
                     file:bg-rose-100 file:text-rose-800
                     hover:file:bg-rose-100"
                      />
                    </label>
                    <button
                      onClick={() => UplooadTask('3', kidsFiles)}
                      className="bg-rose-400 mt-6 rounded-md hover:bg-rose-600 text-white font-bold py-4 px-8 w-full mb-8 focus:outline-none focus:shadow-outline"
                    >
                      Submit
                    </button>
                    <div className="mt-4">
                      {kidsFiles.map((file, index) => {
                         const fileURL = URL.createObjectURL(file);
                         const isVideo = file.type.startsWith('video/');
                         return (
                           <div key={index} className="relative inline-block mr-2">
                             {isVideo ? (
                               <video
                                 controls
                                 className="h-40 w-40 object-cover rounded"
                               >
                                 <source src={fileURL} type={file.type} />
                                 Your browser does not support the video tag.
                               </video>
                             ) : (<>
                               <img
                                 src={fileURL}
                                 alt="selected"
                                 className="h-20 w-20 object-cover rounded"
                               />
                               <span style={{color:"red"}}>Image  File is Not <br/> Accepted please remove it </span>
                               </>
                             )}
                             <button
                               className="absolute top-0 right-0 bg-red-500 text-white rounded-full h-6 w-6 flex items-center justify-center"
                               onClick={() => removeFile(index, 'kids')}
                             >
                               &times;
                             </button>
                           </div>
                         );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> </>) : (
          paymentStatusPending(payamount, showForm1Loader, FullScreenOverlay))}
      </>) : (
        <>
          <div style={{ height: '600px' }} className="container flex justify-center mx-auto items-center">
            <div className="rounded-lg msgtwolevel shadow-lg pb-5">
              <center>
                <svg
                  className="checkmark h-12 w-12 text-green-500 animate-checkmark"
                  viewBox="0 0 52 52"
                >
                  <circle
                    className="checkmark-circle"
                    cx="26"
                    cy="26"
                    r="23"
                    fill="none"
                  />
                  <path
                    className="checkmark-check"
                    fill="none"
                    d="M14 27l7 7 16-16"
                  />
                </svg>
                <style jsx>{`
                      @keyframes stroke {
                        0% {
                          stroke-dasharray: 0, 100;
                        }
                        100% {
                          stroke-dasharray: 100, 0;
                        }
                      }

                      @keyframes scale {
                        0%,
                        100% {
                          transform: none;
                        }
                        50% {
                          transform: scale3d(1.1, 1.1, 1.1);
                        }
                      }

                      .checkmark-container {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                      }

                      .checkmark-circle {
                        stroke: #34d399;
                        stroke-width: 4;
                      }

                      .checkmark-check {
                        stroke: #34d399;
                        stroke-width: 4;
                        stroke-linecap: round;
                        stroke-linejoin: round;
                        stroke-dasharray: 100;
                        stroke-dashoffset: 0;
                        animation: stroke 0.5s cubic-bezier(0.65, 0, 0.45, 1)
                            forwards,
                          scale 0.3s ease-in-out 0.5s forwards;
                      }
                    `}</style>
              </center>
              <h3

                className="text-xl font-bold text-green-800 p-4 "
              >
                🎉 {userData?.name}! 🌟 Please complete the First Level before continuing here. We appreciate your dedication and look forward to seeing you in the Second Level &gt;&gt; <a className=" text-blue-800 " href="/" target="_blank">Continue </a> <br />

              </h3>
              <p className=" text-gray-500 my-1  p-3">
                Please note: The First Level is completely free and does not require any payment.
              </p>


            </div>
          </div>
        </>
      )}
    </div>
  );
};


function paymentStatusPending(payamount, showForm1Loader, FullScreenOverlay) {
  return (
    <>
      <div className="p-2 md:max-w-lg container mx-auto">
        <h2 className="text-2xl font-bold text-black mb-8">
          Activate Member Ship! 💳
        </h2>
        <h2 className="text-2xl font-bold text-black mb-8">
          IDP (CO CURRICULUM ACTIVITIES)
        </h2>
        <p className="text-sm text-gray-500">
          பள்ளிகள் அறிவு சார்ந்த கல்வியை சிறப்பான முறையில் போதித்து வருகின்றனர்.
          மாணவர்களின் தனித் திறமைகளை வளர்க்கும் பொருட்டு ஒருங்கிணைந்த
          செயல்பாடுகள் என்னும் திட்டத்தின் கீழ் INTEGRATED DEVELOPMENT PROGRAM
          (IDP) அன்றாட நிகழ்வுகளை ஒழுங்கமைத்துக் கொள்ளவும், அறிவுக்கூர்மையை
          வளர்த்துக் கொள்ளவும், சமூக பங்களிப்புகளை செவ்வனே செய்யவும் பயிற்சி
          முறை திட்டமிடப்பட்டுள்ளது.
        </p>
        <br />
        <div style={{ position: "relative", padding: "10px" }}>
          <div className="paybox">
            <h2>
              <b>AYYA"WIN NALS SHOPPY</b>
              <span style={{ float: "right" }}>
                <b>₹999/-</b>
              </span>
            </h2>
            <p>
              <span>Description or discount</span>
              <span style={{ float: "right", fontSize: "12px" }}>90 Days</span>
            </p>
            <img className="staricon" src={star} alt="Star" />
          </div>
        </div>
        <br />
        <div>
          <h2>
            <b>You'll get:</b>
          </h2>
        </div>
        <div className="starbox">
          <p className="flex items-center ">
            <img className="mr-5" src={ministar} alt="" />
            தினசரி செயல்பாடுகள் குறித்த பயிற்சிக் கையேடு
          </p>
          <p className="flex items-center ">
            <img className="mr-5" src={ministar} alt="" />
            மரத்தின் விதைகள் மற்றும் நர்சரி கவர்
          </p>
          <p className="flex items-center ">
            <img className="mr-5" src={ministar} alt="" />
            ரூ.260 மதிப்புள்ள சிறுதானிய உணவுப் பொருட்கள் தொகுப்பு
          </p>
          <p className="flex items-center ">
            <img className="mr-5" src={ministar} alt="" />
            வீட்டுத் தோட்ட உபகரணங்கள் தொகுப்பு மற்றும் கையேடு
          </p>
          <p className="flex items-center ">
            <img className="mr-5" src={ministar} alt="" />
            பங்கேற்பாளர் சான்றிதழ் மற்றும் மெடல்
          </p>

          <p className="mt-5 font-bold text-center">
            வகுப்புகள் அனைத்தும் ஆன்லைன் மூலமாக நடைபெறும்.
          </p>

          <p className="font-bold text-center">
            மாவட்ட, மாநில அளவிலான திறன் மேம்பாட்டு நேரடிப் போட்டிகள் நடைபெறும்.
            இடம் மற்றும் நேரம் பின்னர் அறிவிக்கப்படும்.
          </p>
        </div>
        <button
          onClick={(e) => {
            payamount();
          }}
          className="bg-blue-500 input-button mt-4 hover:bg-blue-700 text-white font-bold py-4 px-8 w-full focus:outline-none focus:shadow-outline"
        >
          <div hidden={!showForm1Loader}>
            {/* <div className="loader"></div> */}
            <FullScreenOverlay>
              <CircularProgress style={{color:"green !importtant"}}/>
            </FullScreenOverlay>
          </div>
          Pay
        </button>
      </div>
    </>
  );
}
