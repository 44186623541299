import React from "react";
import "./style.css";
import { Footermain } from "./Footermain";

export const PrivacyandPolicy = () => {
  return (
    <>
      <div className="container mx-auto text-center PrivacyandPolicy">
        <div>Privacy and Policy</div>
      </div>
      <div className="container mx-auto pandpcontent p-5">
        <p>
          Your privacy is very important to us. We do not publish, sell or rent
          your personal information to third parties for their marketing
          purposes without your explicit consent. Please read this privacy
          policy to learn more about the ways in which we use and protect your
          personal information.
        </p>

        <p className="pt-10">
          This privacy policy sets out how we use and protects any information
          that you give us when you use this website. Our Team is committed to
          ensuring that your privacy is protected. Should we ask you to provide
          certain information by which you can be identified when using this
          website, then you can be assured that it will only be used in
          accordance with this privacy statement. We may change this policy from
          time to time by updating this page. You should check this page from
          time to time to ensure that you are happy with any changes.
        </p>
        <h5>What we collect</h5>
        <p>
          We may collect the following information: name contact information
          including email address demographic information such as postcode,
          preferences and interests other information relevant to customer
          surveys and/or offers
        </p>
        <h5>Security</h5>
        <p>
          We are committed to ensuring that your information is secure. In order
          to prevent unauthorized access or disclosure, we have put in place
          suitable physical, electronic and managerial procedures to safeguard
          and secure the information we collect online.
        </p>
        <h5>How we use cookies</h5>
        <p>
          A cookie is a small file which asks permission to be placed on your
          computer's hard drive. Once you agree, the file is added and the
          cookie helps analyse web traffic or lets you know when you visit a
          particular site. Cookies allow web applications to respond to you as
          an individual. The web application can tailor its operations to your
          needs, likes and dislikes by gathering and remembering information
          about your preferences. We use traffic log cookies to identify which
          pages are being used. This helps us analyse data about web page
          traffic and improve our website in order to tailor it to customer
          needs. We only use this information for statistical analysis purposes
          and then the data is removed from the system. Overall, cookies help us
          provide you with a better website, by enabling us to monitor which
          pages you find useful and which you do not. A cookie in no way gives
          us access to your computer or any information about you, other than
          the data you choose to share with us. You can choose to accept or
          decline cookies. Most web browsers automatically accept cookies, but
          you can usually modify your browser setting to decline cookies if you
          prefer. This may prevent you from taking full advantage of the
          website.
        </p>
        <h5>Controlling Your Personal Information</h5>
        <p>
          When filling out forms on our website, simply opt-out of allowing your
          information to be used for direct marketing. If you've previously
          consented to direct marketing, you can change your preference at any
          time by emailing us at <a href="">nalsshoppy@gmail.com</a>
        </p>

        <p>
          We do not sell, distribute, or lease your personal information without
          your permission, except where required by law. However, we may use
          your information to send you promotions from third parties if you've
          indicated interest. You can request details of the personal
          information we hold about you under the Data Protection Act 1998, with
          a small fee applicable.{" "}
        </p>

        <p className="pt-8">
          A small fee will be payable. If you would like a copy of the
          information held on you please write to 43, 1St floor, 3rd cross,
          laxmipuram, Ganapathy - 641006 .
        </p>

        <p className="pt-8">
          If you believe any information we hold is incorrect, please inform us
          promptly via email. We are committed to promptly correcting any
          inaccuracies. We offer an option for users to unsubscribe from our
          mailing list.
        </p>
      </div>
      <Footermain/>
    </>
  );
};
