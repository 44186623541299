import React, { useState } from 'react';

export const Otppage = () => {
  // State to manage visibility of OTP section
  const [showOtp, setShowOtp] = useState(false);

  // Function to handle "Next" button click
  const handleNextClick = () => {
    // Toggle the visibility of OTP section
    setShowOtp(true);
  };

  return (
    <div> 
      {!showOtp ? ( // Render password reset section if showOtp is false
        <div className="mt-16 pt-16 flex items-center justify-center bg-white">
          <div className="max-w-md w-full px-6 loginpage">
            <h2 className="text-2xl text-center font-bold text-black mb-8">Reset your password</h2>
            <p className="text-sm text-center text-gray-500 mb-8">Please enter your registered email address to reset your password</p>
            <div className="mb-3">
              <input
                className=""
                id="email"
                type="email"
                placeholder="Email Address"
              />
            </div>
            <button
              className="bg-blue-500 mt-4 hover:bg-blue-700 text-white font-bold py-4 px-8 w-full focus:outline-none focus:shadow-outline"
              onClick={handleNextClick} // Call handleNextClick function on button click
            >
              Next
            </button>
            <p className="text-gray-500 text-center text-sm mt-4 ">Reset via  <a href="/signup" className="text-blue-500 hover:text-blue-600 font-bold">Mobile Number</a></p>
            <p className="text-gray-500 text-center text-sm mt-4">Back to <a href="/signin" className="text-blue-500 hover:text-blue-600 font-bold">Sign in</a></p>
          </div>
        </div>
      ):(
        // Render OTP section if showOtp is true
        <div className="mt-16 pt-16 flex items-center justify-center bg-white">
          <div className="max-w-md w-full px-6 loginpage">
            <h2 className="text-2xl text-center font-bold text-black mb-8">Enter confirmation code</h2>
            <p className="text-sm text-center text-gray-500 mb-8">A 4-digit code was sent to jiyathlimran@gmail.com</p>
            <div className="mb-3 flex justify-center">
              {/* OTP input fields */}
              <input
                className="border border-gray-300 rounded-md px-3 py-2 text-center w-16 mr-2"
                id="digit1"
                type="text"
                maxLength="1"
                placeholder="0"
              />
              <input
                className="border border-gray-300 rounded-md px-4 py-2 text-center w-16 mr-2"
                id="digit2"
                type="text"
                maxLength="1"
                placeholder="0"
              />
              <input
                className="border border-gray-300 rounded-md px-4 py-2 text-center w-16 mr-2"
                id="digit3"
                type="text"
                maxLength="1"
                placeholder="0"
              />
              <input
                className="border border-gray-300 rounded-md px-4 py-2 text-center w-16"
                id="digit4"
                type="text"
                maxLength="1"
                placeholder="0"
              />
            </div>
            <p className='text-center text-blue-500 hover:text-blue-600 font-bold mt-6' style={{cursor:"pointer"}}>Resend code</p>
            <button className="bg-blue-500 mt-4 hover:bg-blue-700 text-white font-bold py-4 px-8 w-full focus:outline-none focus:shadow-outline">
              Continue
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
    