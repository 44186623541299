import React, { useState } from 'react'
import righttick from "../images/Red-cross.png";

export const PaymentfailedPage = () => {
  const [activeItem, setActiveItem] = useState("Dashboard");
  const handleClick = (item) => {
    setActiveItem(item);
  };

  return (
    <>
       <div className="container mx-auto taskpagecon mt-">
        <div>
        <div className="div">
          <a
            href="#"
            onClick={() => handleClick("Dashboard")}
            className={
              activeItem === "Dashboard" ? "taskpagehead" : "taskpagehead2"
            }
          >
            Dashboard
          </a>
          <a
            href="#"
            // onClick={() => handleClick("Tasks")}
            className={
              activeItem === "Tasks" ? "taskpagehead" : "taskpagehead2"
            }
          >
            Tasks
          </a>
          <a
            href="#"
            onClick={() => handleClick("My account")}
            className={
              activeItem === "My account" ? "taskpagehead" : "taskpagehead2"
            }
          >
            My account
          </a>
        </div>
        </div>
      
      </div>
    <div className="succestick container mx-auto mt-16 pt-16 p-4">
    <img src={righttick} width={'200px'} /> 
    <h2 className="text-4xl text-center font-bold text-black mb-8">
     
    </h2>
    <center>
      {" "}
      <p className="font-bold text-red-900" >Oops! It seems there was an issue processing your payment. <br /> Please double-check your payment details and try again.</p>
    </center>
    <a className="inline-block px-6 py-3 font-bold mt-16 text-lg transition duration-300 ease-in-out transform border border-transparent rounded-md shadow-md text-white bg-red-500 hover:bg-red-600 hover:shadow-lg" href="/mytask">Back to Home</a>


  </div>
  </>

  )
}
