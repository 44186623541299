import React, { useEffect, useState } from 'react';
import img1 from "../images/ima (2).png";
import taskcom from '../images/taskcomplete.png';
import Confetti from 'react-confetti';
import { useWindowSize } from 'react-use';
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

const cache = {};

export const TaskSubmit = () => {
  const [showComment, setShowComment] = useState(false);
  const [showbtn, setShowbtn] = useState(false);
  const [commentInput, setCommentInput] = useState('');
  const [files, setFiles] = useState([]);
  const [comments, setComments] = useState([]);
  const [finishedmsg, setFinishedmsg] = useState(true);
  const [isChecked, setIsChecked] = useState(false);
  const [errors, setErrors] = useState({ comment: '', files: '', checkbox: '' });
  const [showForm1Loader, setShowForm1Loader] = useState(false);
  const [result, setResult] = useState("");

  const userDataFromStorage = localStorage.getItem('userData');
  const userData = JSON.parse(userDataFromStorage);

  const handleCommentClick = () => {
    setShowComment(!showComment);
  };
  

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  const FullScreenOverlay = styled(Box)(({ theme }) => ({
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    zIndex: 1300,
  }));

  const handleTaskfinished = () => {
    const currentDate = new Date().toLocaleString();
    const newComment = {
      text: commentInput,
      date: currentDate,
      files: files
    };

    let newErrors = { comment: '', files: '', checkbox: '' };
    let isValid = true;

    if (!commentInput) {
      newErrors.comment = 'Comment is required.';
      isValid = false;
    }

    if (files.length === 0) {
      newErrors.files = 'At least one file is required.';
      isValid = false;
    }

    if (!isChecked) {
      newErrors.checkbox = 'You must agree to the terms.';
      isValid = false;
    }

    setErrors(newErrors);

    if (isValid) {
      setComments([newComment]);
      setShowComment(false);
      setShowbtn(true);
    }
  };

  const { width, height } = useWindowSize();

  const [tasklist, setTasklist] = useState([]);
  const [taskday, setTaskday] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      if (cache['tasks']) {
        const data = cache['tasks'];
        processTaskData(data);
      } else {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${userData?.token}`);

        const requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow"
        };

        try {
          const response = await fetch("https://api.ayyawinstudyfoundation.org/api/tasks", requestOptions);
          const data = await response.json();
          cache['tasks'] = data;
          processTaskData(data);
        } catch (error) {
          console.error("Error fetching tasks:", error);
        }
      }
    };

    const processTaskData = (data) => {
      setTasklist(data.data.tasks);
      const tasks = data.data.tasks;

      let firstEnabledTask = null;
      for (let i = 0; i < tasks.length; i++) {
        if (tasks[i].disabled === false) {
          firstEnabledTask = tasks[i];
          break;
        }
      }

      if (firstEnabledTask) {
        setTaskday(firstEnabledTask.id);
      } else {
        console.log("No enabled tasks found.");
      }
    };

    fetchData();
  }, []);

  const [task, setTask] = useState({});
  const [taskStatus, setTaskStatus] = useState(0);
  const [userTaskDetails, setUserTaskDetails] = useState(null);

  useEffect(() => {
    if (taskday) {
      if (cache[`task_${taskday}`]) {
        const data = cache[`task_${taskday}`];
        setTask(data.task);
        setTaskStatus(data.task_status);
        setUserTaskDetails(data.user_task_details);
      } else {
        const fetchTaskDetails = async () => {
          const myHeaders = new Headers();
          myHeaders.append("Authorization", `Bearer ${userData?.token}`);

          const formdata = new FormData();

          const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formdata,
            redirect: "follow"
          };

          try {
            const response = await fetch(`https://api.ayyawinstudyfoundation.org/api/task/${taskday}`, requestOptions);
            const result = await response.json();
            const data = result.data;
            cache[`task_${taskday}`] = data;
            setTask(data.task);
            setTaskStatus(data.task_status);
            setUserTaskDetails(data.user_task_details);
          } catch (error) {
            console.error(error);
          }
        };

        fetchTaskDetails();
      }
    }
  }, [taskday]);

  const handleFileChange = (event) => {
    setFiles(Array.from(event.target.files));
  };

  const handleSubmit = async () => {
    setShowForm1Loader(true);

    if (files.length === 0) {
      console.error("No file selected");
      return;
    }

    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${userData?.token}`);

    const formdata = new FormData();
    formdata.append("status", "1");
    formdata.append("description", commentInput);
    files.forEach(file => {
      formdata.append("media_gallery[]", file, file.name);
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow"
    };

    try {
      const response = await fetch(`https://api.ayyawinstudyfoundation.org/api/task/update/${taskday}`, requestOptions);
      const text = await response.text();
      setResult(text);
    } catch (error) {
      console.error("Error:", error);
    }

    setShowForm1Loader(false);
    setFinishedmsg(false);
    setTimeout(() => {
      window.location.reload();
    }, 5000);
  };

  let mediaContent = null;

  if (task.media_gallery) {
    try {
      const mediaGallery = JSON.parse(task.media_gallery);

      if (Array.isArray(mediaGallery) && mediaGallery.length > 0) {
        const firstItemUrl = mediaGallery[0];
        const fileExtension = firstItemUrl.split('.').pop().toLowerCase();

        if (fileExtension === 'mp4' || fileExtension === 'webm') {
          mediaContent = (
            <video controls>
              <source src={`https://api.ayyawinstudyfoundation.org/${firstItemUrl}`} type={`video/${fileExtension}`} />
              Your browser does not support the video tag.
            </video>
          );
        } else {
          mediaContent = <img src={`https://api.ayyawinstudyfoundation.org/${firstItemUrl}`} alt="Task Image" />;
        }
      }
    } catch (error) {
      console.error("Error parsing media_gallery JSON:", error);
    }
  }

  return (
    <div>
      <div hidden={!showForm1Loader}>
        {/* <div className="loader"></div> */}
        <FullScreenOverlay>
          <CircularProgress style={{color:"green"}} />
        </FullScreenOverlay>
      </div>

      {finishedmsg ? (
        <div className="container mx-auto p-5 block md:flex">
          <div className="w-full mt-5 flex justify-center md:p-10 p-0">
            {mediaContent}
          </div>
          <div>
            <div className="tasksubcontent">
              <h2>Day {task.id} - {task.title}</h2>
              <p>{task.description}</p>
              <p>{task.content}</p>
            </div>
            <h2 className='cmtsectionhead'>Your Explanations</h2>
            <div>
              <div>
                <pre>{task.message}</pre>
              </div>
            </div>

            {comments.map((comment, index) => (
              <div key={index} className='cmtsection mb-5'>
                <div>
                  <p>{userData?.name}</p>
                  <p>{comment.text}</p>
                                {comment.files && comment.files.map((file, fileIndex) => (
                  <div key={fileIndex} className='cmtsectionfile mt-5'>{file.name}</div>
                ))}
                  <span className='pt-3'>{comment.date}</span>
                </div>
  
              </div>
            ))}

            {showComment && (
              <div className='cmtsectioninputs'>
       <textarea
  value={commentInput}
  onChange={(e) => setCommentInput(e.target.value)}
  placeholder="Enter your comment"
  className="w-full h-32 p-4 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent bg-white text-gray-700"
></textarea>

                {errors.comment && <div className="text-red-500">{errors.comment}</div>}

                <label htmlFor="fileInput" className="mt-2 block w-full">
                    <input
                      multiple
                      id="fileInput"
                      type="file"
                      onChange={handleFileChange}
                       className="mb-4 block w-full text-sm mt-5 text-gray-500
                   file:mr-4 file:py-2 file:px-4
                   file:rounded-full file:border-0
                   file:text-sm file:font-semibold
                   file:bg-violet-50 file:text-violet-700
                   hover:file:bg-violet-100"
                    />
                </label>
                {errors.files && <div className="text-red-500">{errors.files}</div>}

                <div className="flex items-center mt-5" >
                <div >
                <input
  checked={isChecked}
  id="custom-checkbox"
  type="checkbox"
  onChange={handleCheckboxChange}
  className="custom-checkbox w-5 h-5 text-green-600 bg-transparent border-gray-300 rounded-full focus:ring-green-500 transition-all duration-300 ease-in-out"
/>
</div>
                  <label htmlFor="green-checkbox" className="ml-2 text-sm font-medium text-gray-500">
                    Thank you for submitting your details! Please click the button below to confirm your submission.
                  </label>
                </div>
                {errors.checkbox && <div className="text-red-500">{errors.checkbox}</div>}

                <button onClick={handleTaskfinished} className="bg-green-600 mt-6 rounded-md hover:bg-green-700 text-white font-bold py-4 px-8 w-full mb-8 focus:outline-none focus:shadow-outline">
                  Submit
                </button>
              </div>
            )}

            <div className='flex items-center justify-between tastsubbtn mt-10'>
              <button className="hover:bg-blue-700 font-bold py-4 px-8 w-full mb-8" onClick={handleCommentClick}>
                {showComment ? 'Cancel' : 'Comment'}
              </button>
              {showbtn && (
                <button onClick={handleSubmit} className="bg-blue-600 ml-5 hover:bg-blue-700 text-white font-bold py-4 px-8 w-full mb-8 focus:outline-none focus:shadow-outline">
                  Send Task 
                </button>
              )}
            </div>
          </div>
        </div>
      ) : (
        <>
          <Confetti width={width} height={height} />
          <div className="container finishedmsg mx-auto max-w-lg p-5">
            <img src={taskcom} className='w-full' alt="" />
            <p className='font-bold text-center mt-5'>Day {task.id} - {task.title} has been completed successfully</p>
          </div>
        </>
      )}
    </div>
  );
};
