import React, { useCallback, useEffect, useRef, useState } from "react";
import righttick from "../images/righttick.png";
import { AK } from "../constants/AppKeys";
import axios from "axios";
import personal from "../images/personal.png";
import address from "../images/Groupsss.png";
import educational from "../images/education-graduation.png";
import password from "../images/pass.png";
import star from "../images/Recommended.png";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../store/user/actions";

import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import ministar from "../images/Star Filled.png";
import { validateToken } from "../utilities/sessionexpiry";
export const Signup = () => {
  const [showAdditionalFields, setShowAdditionalFields] = useState(false);
  const [showForm1, setShowForm1] = useState(false);
  const [showForm2, setShowForm2] = useState(false);
  const [showForm3, setShowForm3] = useState(false);
  const [showForm4, setShowForm4] = useState(false);
  const [showForm5, setShowForm5] = useState(false);
  const [showForm6, setShowForm6] = useState(false);
  const [showForm7, setShowForm7] = useState(false);
  const [showForm8, setShowForm8] = useState(false);

  const [showForm1Loader, setShowForm1Loader] = useState(false);
  const [showForm2Loader, setShowForm2Loader] = useState(false);
  const [showForm3Loader, setShowForm3Loader] = useState(false);
  const [showForm4Loader, setShowForm4Loader] = useState(false);
  const [showForm5Loader, setShowForm5Loader] = useState(false);
  const [showForm6Loader, setShowForm6Loader] = useState(false);
  const [showForm7Loader, setShowForm7Loader] = useState(false);
  const [showForm8Loader, setShowForm8Loader] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [formData, setFormData] = useState(new FormData());
  const [formdata, setFormdata] = useState(new FormData());
  const [fieldErrors, setFieldErrors] = useState({});
  const [pageinit, setPageInit] = useState(false);
  const [usertoken, setusertoken] = useState("");
  const [selectedSchool, setSelectedSchool] = useState('0');
  // const [school, setSchool] = useState('');
  const [new_school, setNewSchool] = useState('');



  const [count, setCount] = useState(1);
  let dispatch = useDispatch();
  useEffect(() => {
    console.log("***");
  }, [count]);

  const FullScreenOverlay = styled(Box)(({ theme }) => ({
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    zIndex: 1300, // Higher than most other elements
  }));

  const [sfD, setsfD] = useState({
    // utm_campaign:"",
    mobile: "",
    confirmationcode: "",
    name: "",
    email: "",
    type: "",
    city: "",
    address1: "",
    address2: "",
    district: "",
    state: "",
    postalcode: "",
    education: "",
    school: "",
    new_school: "",
    institute: "",
    idcard: null,
    digitcode: "",
    id_card_photo: "",

    digit1: "",
    digit2: "",
    digit3: "",
    digit4: "",
    digit5: "",
    digit6: "",

    mobileError: false,
    confirmationcodeError: false,
    nameError: false,
    emailError: false,
    typeError: false,
    cityError: false,
    districtError: false,
    stateError: false,
    postalcodeError: false,
    educationError: false,
    schoolError: false,
    new_schoolError: false,
    instituteError: false,
    idcardError: false,
    digitcodeError: false,
    address1Error: false,
    address2Error: false,
    id_card_photoError: false,

    mobileErrorMsg: "",
    confirmationcodeErrorMsg: "",
    address1ErrorMsg: "",
    address2ErrorMsg: "",
    nameErrorMsg: "",
    emailErrorMsg: "",
    typeErrorMsg: "",
    cityErrorMsg: "",
    districtErrorMsg: "",
    stateErrorMsg: "",
    postalcodeErrorMsg: "",
    educationErrorMsg: "",
    schoolErrorMsg: "",
    new_schoolErrorMsg: "",
    instituteErrorMsg: "",
    idcardErrorMsg: "",
    digitcodeErrorMsg: "",
    id_card_photoErrorMsg: "",
  });

  const reqeustOTP = async (e) => {
    try {
      const formData = new FormData();
      formData.append("mobile", sfD.mobile);

      return await axios
        .post(AK.APIURL + AK.REQUESTOTPAPI, formData)
        .then((response) => {
          console.log(response);
          return true;
        });
    } catch (error) {
      console.log(error);
      changeInput(error?.response?.data?.message, "mobileErrorMsg");
      changeInput(true, "mobileError");
      console.error(error);
    }
    return false;
  };

  const submitOTP = async (e) => {
    try {
      //   const urlSearchParams = new URLSearchParams(window.location.search);
      //   const utm_campaign = urlSearchParams.get('utm_campaign');
      //  console.log(utm_campaign,"utm_campaign")
      const formData = new FormData();
      formData.append("mobile", sfD.mobile);
      // if(utm_campaign !== ""){
      // formData.append("utm_campaign",utm_campaign);
      // }
      formData.append(
        "otp",
        sfD.digit1 +
          sfD.digit2 +
          sfD.digit3 +
          sfD.digit4 +
          sfD.digit5 +
          sfD.digit6
      );

      return await axios
        .post(AK.APIURL + AK.SUBMITOTPAPI, formData)
        .then((response) => {
          console.log(response);
          return true;
        });
    } catch (error) {
      changeInput(error?.response?.data?.message, "mobileErrorMsg");
      changeInput(true, "mobileError");
      console.error(error);
    }
    return false;
  };

  const submitForm = async (e) => {
    try {

      console.log(selectedSchool,"selectedSchool")
      const formData = new FormData();
      formData.append("type", sfD.type);
      formData.append("university", sfD.school);
      formData.append("address1", sfD.address1);
      formData.append("address2", sfD.address2);
      formData.append("city", sfD.city);
      formData.append("district", sfD.district);
      formData.append("state", sfD.state);
      formData.append("education", sfD.education);
      formData.append("name", sfD.name);
      formData.append("mobile", sfD.mobile);
      formData.append("email", sfD.email);
      formData.append("institute", sfD.institute);
    if (selectedSchool === "Others") {
      formData.append("new_school", sfD.new_school);
    } else if (selectedSchool > 0) {
      formData.append("school", selectedSchool); 
    } else {
      console.error("Invalid school selection");
      return;
    }

// console.log("school",school)
      let id_card_photoFile = document.querySelector("#id_card_photo");
      if (id_card_photoFile && id_card_photoFile.files[0] !== null) {
        formData.append("id_card_photo", id_card_photoFile.files[0]);
      }

      return await axios
        .post(AK.APIURL + AK.SUBMITSIGNUPAPI, formData)
        .then((response) => {
          console.log(response);
          return true;
        });
    } catch (error) {
      changeInput(error?.response?.data?.message, "id_card_photoMsg");
      changeInput(true, "id_card_photoError");
      console.error(error);
    }
    return false;
  };

  const [showContent, setShowContent] = useState(false);
  const [showButton, setShowButton] = useState(true);

  const toggleContent = () => {
    setShowContent(!showContent);
    setShowButton(false);
  };

  const toggleButton = () => {
    setShowContent(false);
    setShowButton(true);
  };

  const userDataFromStorage = localStorage.getItem("userData");

  const userData = JSON.parse(userDataFromStorage);

  const payment = userData?.payment;
  const payment_status = userData?.payment_status;

  const payamount = async (e) => {
    try {
      var amount = 1500;
      let payload = {
        amount: amount,
      };

      const headers = {
        Authorization: `Bearer ${userData?.token}`,
        "Content-Type": "application/json",
      };

      return await axios
        .post(AK.APIURL + AK.HAODAPAY, payload, {
          headers,
          maxContentLength: Infinity,
          maxBodyLength: Infinity,
        })
        .then(async (response) => {
          if (
            response != typeof undefined &&
            response.data != typeof undefined
          ) {
            if (response?.data?.data?.payment_link != "") {
              window.location.href = response?.data?.data?.payment_link;
            }
          }
          return true;
        });
    } catch (error) {
      console.error(error);
    }
    return false;
  };

  // console.log("payment:", payment);
  // console.log("payment_status:", payment_status);

  if (payment_status === "FAILED") {
    window.location.href = "/paymenterror";
  }

  const [fileInput, setFileInput] = useState(null);

  const handleSubmit2 = async (event) => {
    event.preventDefault();

    // Check if file input is empty or not selected
    if (!fileInput) {
      console.error("No file selected");
      return;
    }

    formdata.append("step", "2");
    formdata.append("type", "1");
    formdata.append("university", "Anna University");
    formdata.append("city", "Chennai");
    formdata.append("district", "Chennai");
    formdata.append("state", "TamilNadu");
    formdata.append("school", "BCA");

    const requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        "https://api.ayyawinstudyfoundation.org/api/register",
        requestOptions
      );

      // Check if response status is 200
      if (response.status === 200) {
        setShowAdditionalFields(true);
      } else {
        console.error("Failed with status:", response.status);
      }

      const result = await response.text();
      console.log(result);
    } catch (error) {
      console.error(error);
    }
  };

  const [currentTime, setCurrentTime] = useState(new Date());
  const [redirectingsec, setredirectingsec] = useState(3);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(intervalId); // Cleanup function to prevent memory leaks
  }, []);

  const getTimeRemaining = () => {
    const totalTime = currentTime;
    const seconds = Math.floor((totalTime / 1000) % 60);
    const minutes = Math.floor((totalTime / (1000 * 60)) % 60);
    const hours = Math.floor((totalTime / (1000 * 60 * 60)) % 24);
    const days = Math.floor(totalTime / (1000 * 60 * 60 * 24));

    return {
      days,
      hours,
      minutes,
      seconds,
    };
  };

  const { days, hours, minutes, seconds } = getTimeRemaining();

  const handleChange = (e) => {
    const { name, value } = e.target;
    formData.append(name, value);
    // Clear error message for the field when user starts typing again
    if (value === "")
      setFieldErrors({
        ...fieldErrors,
        [name]: "Please fill this " + name + " field.",
      });
    else
      setFieldErrors({
        ...fieldErrors,
        [name]: "",
      });
  };

  const allhideForm = () => {
    setShowForm8(false);
    setShowForm7(false);
    setShowForm6(false);
    setShowForm5(false);
    setShowForm4(false);
    setShowForm3(false);
    setShowForm2(false);
    setShowForm1(false);
  };

  const handleSubmitForm = async (e, formname) => {
    e.preventDefault();

    // mobile: "",
    // confirmationcode: "",
    // name: "",
    // email: "",
    // type: "",
    // city: "",
    // district: "",
    // state: "",
    // postalcode: "",
    // school: "",
    // idcard: null,
    // digitcode: "",

    changeInput("", "mobileErrorMsg");
    changeInput("", "nameErrorMsg");
    changeInput("", "emailErrorMsg");
    changeInput("", "typeErrorMsg");
    changeInput("", "address1ErrorMsg");
    changeInput("", "address2ErrorMsg");
    changeInput("", "cityErrorMsg");
    changeInput("", "districtErrorMsg");
    changeInput("", "stateErrorMsg");
    changeInput("", "stateErrorMsg");
    changeInput("", "instituteErrorMsg");
    changeInput("", "educationErrorMsg");
    changeInput("", "schoolErrorMsg");
    changeInput("", "new_schoolErrorMsg");

    changeInput(false, "mobileError");
    changeInput(false, "nameError");
    changeInput(false, "emailError");
    changeInput(false, "typeError");
    changeInput(false, "cityError");
    changeInput(false, "districtError");
    changeInput(false, "stateError");
    changeInput(false, "postalcodeError");
    changeInput(false, "instituteError");
    changeInput(false, "educationError");
    changeInput(false, "schoolError");
    changeInput(false, "new_schoolError");

    let error = false;
    if (formname == "Form1") {
      if (sfD.mobile === "") {
        changeInput(true, "mobileError");
        changeInput("Please enter valid mobile number.", "mobileErrorMsg");
        error = true;
      }

      if (sfD.mobile.length != 10) {
        changeInput(true, "mobileError");
        changeInput("Please enter valid mobile number.", "mobileErrorMsg");
        error = true;
      }
    }

    if (formname == "Form3") {
      if (sfD.name === "") {
        changeInput(true, "nameError");
        changeInput("Please enter valid name.", "nameErrorMsg");
        error = true;
      }
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (sfD.email === "") {
        changeInput(true, "emailError");
        changeInput("Please enter an email.", "emailErrorMsg");
        error = true;
      } else if (!emailPattern.test(sfD.email)) {
        changeInput(true, "emailError");
        changeInput("Please enter a valid email address.", "emailErrorMsg");
        error = true;
      } else {
        changeInput(false, "emailError");
        changeInput("", "emailErrorMsg");
      }

      if (sfD.type === "") {
        changeInput(true, "typeError");
        changeInput("Please enter valid type.", "typeErrorMsg");
        error = true;
      }
    }

    if (formname == "Form4") {
      if (sfD.address1 === "") {
        changeInput(true, "address1Error");
        changeInput("Please enter valid Address.", "address1ErrorMsg");
        error = true;
      }
      if (sfD.address2 === "") {
        changeInput(true, "address2Error");
        changeInput("Please enter valid Address.", "address2ErrorMsg");
        error = true;
      }

      if (sfD.city === "") {
        changeInput(true, "cityError");
        changeInput("Please enter valid city.", "cityErrorMsg");
        error = true;
      }

      if (sfD.district === "") {
        changeInput(true, "districtError");
        changeInput("Please enter valid district.", "districtErrorMsg");
        error = true;
      }

      if (sfD.state === "") {
        changeInput(true, "stateError");
        changeInput("Please enter valid district.", "stateErrorMsg");
        error = true;
      }

      if (sfD.postalcode === "") {
        changeInput(true, "postalcodeError");
        changeInput("Please enter valid district.", "postalcodeErrorMsg");
        error = true;
      }
    }

    if (formname == "Form5") {
      if (sfD.education === "") {
        changeInput(true, "educationError");
        changeInput("Please enter valid Education College .", "educationErrorMsg");
        error = true;
      }
      console.log(sfD.school)
      if (sfD.school === 0 || sfD.school === '' || sfD.school === '0') {
        changeInput(true, "schoolError");
        changeInput("Please enter valid School College .", "schoolErrorMsg");
        error = true;
      }
  
      if (sfD.school === 'Others') {
        if (sfD.new_school === "") {
          changeInput(true, "new_schoolError");
          changeInput("Please enter valid New School College .", "new_schoolErrorMsg");
          error = true;
        }
      }
      
      if (sfD.institute === "") {
        changeInput(true, "instituteError");
        changeInput("Please enter valid Institute.", "instituteErrorMsg");
        error = true;
      }

      let id_card_photoFile = document.querySelector("#id_card_photo");
      console.log(id_card_photoFile);
      if (!id_card_photoFile.files || id_card_photoFile.files.length === 0) {
        changeInput(true, "id_card_photoError");
        changeInput("Please upload a valid file.", "id_card_photoErrorMsg");
        error = true;
      } else {
        changeInput(false, "id_card_photoError");
        changeInput("", "id_card_photoErrorMsg");
      }
    }

    if (error) {
      return;
    }

    if (formname == "Form1") {
      setShowForm1Loader(true);
      if (await reqeustOTP()) {
        allhideForm();
        setShowForm2(true);
      }
      setShowForm1Loader(false);
    } else if (formname == "Form2") {
      setShowForm2Loader(true);
      if (await submitOTP()) {
        allhideForm();
        setShowForm3(true);
      }
      setShowForm2Loader(false);
    } else if (formname == "Form3") {
      allhideForm();
      setShowForm4(true);
    } else if (formname == "Form4") {
      allhideForm();
      setShowForm5(true);
    } else if (formname == "Form5") {
      setShowForm5Loader(true);
      if (await submitForm()) {
        allhideForm();
        setShowForm6(true);
      }
      setredirectingsec(3);
      for (var i = 3; i > 0; i--) {
        setInterval(async () => {
          setredirectingsec(i);
          setCount((count) => (count = count + 1));
          if (i === 0) {
            // allhideForm();
            setShowForm6(false);
            setShowForm7(true);
            setTimeout(() => {
              window.location.href = "/signin";
            }, 1000);
            // allhideForm();
          }
        }, 1000);
      }

      setShowForm5Loader(false);
    } else if (formname == "Form6") {
      allhideForm();
      setShowForm8(true);
      setTimeout(() => {
        window.location.href = "/signin";
      }, 1000);
    } else if (formname == "Form7") {
      payamount();
      // allhideForm();
      // setShowForm8(true);
    }
  };

  const inputRefs = useRef(Array.from({ length: 6 }, () => React.createRef()));

  const changeInput = useCallback(async (value, field, inputIndex) => {
    console.log("value",value, "field",field)
    if (field === "school") {
      setsfD({ ...sfD, school: value });
    } else if (field === "new_school") {
      setsfD({ ...sfD, new_school: value });
    }

    if (field === "mobile") {
      if (value?.length > 10) {
        setsfD({ ...sfD, mobileErrorMsg: "Mobile number must be 10 digits" });
      } else {
        setsfD({ ...sfD, mobileErrorMsg: "" });
      }
    }
 if (value === '' && inputIndex > 0) {
      // Move focus to the previous input if value is empty
      handleFocus(inputIndex - 1);
    } else if (value?.length > 0 && inputIndex < inputRefs.current.length - 1) {
      // Move focus to the next input if value length is greater than 0
      handleFocus(inputIndex + 1);
    }
    sfD[field] = value;
    setsfD(sfD);
    setCount((count) => (count = count + 1));
  });

  if (!pageinit) {
    setPageInit(true);
    allhideForm();
    setShowForm1(true);
    // setShowForm2(false);
    // setShowForm3(false);
    // setShowForm7(true);
  }
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };
    const handleFocus = (index) => {
    if (inputRefs.current[index]?.current) {
      inputRefs.current[index].current.focus();
    }
  };

   const handleKeyDown = (e, index) => {
    const value = e.target.value;
    if (e.key === 'Backspace' && value === '') {
      // Move focus to the previous input if Backspace is pressed and input is empty
      if (index > 0) {
        handleFocus(index - 1);
      }
    } else if (e.key === 'ArrowRight' && value?.length > 0) {
      // Move focus to the next input if ArrowRight is pressed and input has value
      if (index < inputRefs.current.length - 1) {
        handleFocus(index + 1);
      }
    }
  };

  const [schools, setSchools] = useState([]);

  useEffect(() => {
    const fetchSchools = async () => {
      try {
        const myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        // myHeaders.append("Authorization", "Bearer 14|T2SoAtoI1faXyaIkFMnxGL3m8dSArVyTd079oQA3");

        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: new FormData(),
          redirect: "follow"
        };

        const response = await fetch("https://api.ayyawinstudyfoundation.org/api/schools", requestOptions);
        const result = await response.json();
        
        if (result.success) {
          setSchools(result.data); // Save the fetched school data
          console.log(result.data);
        } else {
          console.error('Failed to fetch schools:', result.message);
        }
      } catch (error) {
        console.error('Error fetching schools:', error);
      }
    };

    fetchSchools();
  }, []);


  return (
    <div className="mt-2 pt-10 container mx-auto px-2 md:px-16 flex items-center justify-center bg-white">
      <div className="max-w-lg mt-3 md:mt-16 mx-auto container w-full px-6 ">
        <div className="grid gap-6 ">
          <form>
            {showForm1 ? (
              <>
                <h2
                  className="text-4xl font-bold text-black mb-8"
                  style={{ display: "flex", fontFamily: "Montserrat" }}
                >
                  Sign Up &nbsp;&nbsp;&nbsp;{" "}
                  <div hidden={!showForm1Loader}>
                    {/* <div className="loader"></div> */}
                    <FullScreenOverlay>
                      <CircularProgress />
                    </FullScreenOverlay>
                  </div>
                </h2>
                <p
                  className="text-md mb-8"
                  style={{ fontFamily: "Nunito Sans" }}
                >
                  Ready to make a difference? Create an account, plant trees,
                  and join our green movement for a sustainable future! 🌍🌿
                  #Joinayyawinsfoundation
                </p>
                <div className="mb-2">
                  <label
                    htmlFor="mobile"
                    className="block font-bold text-black mb-2"
                  >
                    Mobile Number
                  </label>
                  <input
                                         onChange={(e) => { const newValue = e.target.value.replace(/\D/g, '');
    changeInput(newValue, "mobile");
  }}
                    name="mobile"
                    id="mobile"
                    type="text"
                    placeholder="Enter your mobile number"
                    className="input-field"
                  />
                  <p className="text-red-500">{sfD.mobileErrorMsg}</p>
                </div>
                <button
                  onClick={(e) => handleSubmitForm(e, "Form1")}
                  className="bg-blue-500 mt-6 input-button hover:bg-blue-700 text-white font-bold py-4 px-8 w-full mb-8 focus:outline-none focus:shadow-outline"
                >
                  Next
                </button>
              </>
            ) : (
              <></>
            )}

            {showForm2 ? (
              <>
                <div className="mt-2 pt-2 flex items-center justify-center bg-white">
                  <div className="max-w-md w-full px-6 loginpage">
                    <h2 className="text-2xl text-center font-bold text-black mb-8">
                      Enter confirmation code
                    </h2>
                    <p className="text-sm text-center text-gray-500 mb-8">
                      A 6-digit code was sent to {sfD.mobile}
                    </p>
                    <div className="mb-3 flex justify-center">
                      {/* OTP input fields */}
                      {Array.from({ length: 6 }, (_, index) => (
                        <input
                          key={index}
                          className="border border-gray-300 rounded-md px-4 py-2 text-center w-16 mr-2"
                          id={`digit${index + 1}`}
                          onChange={(e) =>
                            changeInput(
                              e.target.value,
                              `digit${index + 1}`,
                              index
                            )
                          }
                          onKeyDown={(e) => handleKeyDown(e, index)}
                          type="text"
                          maxLength="1"
                          placeholder="0"
                          ref={inputRefs.current[index]}
                        />
                      ))}
                    </div>
                    <p className="text-red-500">{sfD.mobileErrorMsg}</p>
             
                    <button
                      onClick={(e) => handleSubmitForm(e, "Form2")}
                      className="bg-blue-500 mt-4 hover:bg-blue-700 text-white font-bold py-4 px-8 w-full focus:outline-none focus:shadow-outline"
                    >
                      Continue
                    </button>
                           <p
                      className="text-center text-blue-500 hover:text-blue-600 font-bold mt-6"
                      onClick={(e) => handleSubmitForm(e, "Form1")}
                      style={{ cursor: "pointer" }}
                    >
                      Resend code
                    </p>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}

            {showForm3 ? (
              <>
                <img src={personal} width={"100%"} alt="fd" />
                <h2
                  className="text-2xl font-bold text-black mb-4"
                  style={{ fontFamily: "inherit" }}
                >
                  Personal Details
                </h2>
                <p
                  className="text-md mb-4"
                  style={{ fontFamily: "inherit", color: "#71727A" }}
                >
                  Tell Us About Yourself! ✨
                </p>
                <div>
                  <div className="mb-2">
                    <label
                      htmlFor="name"
                      className="block font-bold text-black mb-2"
                    >
                      Name
                    </label>
                    <input
                      id="name"
                      type="text"
                      placeholder="Enter your name"
                      className="input-field"
                      onChange={(e) => changeInput(e.target.value, "name")}
                      name="name"
                    />
                    <p className="text-red-500">{sfD.nameErrorMsg}</p>
                  </div>
                  <div className="mb-2">
                    <label
                      htmlFor="mobile"
                      className="block font-bold text-black mb-2"
                    >
                      Mobile Number
                    </label>
                    <input
                      onChange={(e) => changeInput(e.target.value, "mobile")}
                      name="mobile"
                      id="mobile"
                      type="number"
                      disabled
                      value={sfD?.mobile}
                      placeholder="Enter your mobile number"
                      className="input-field"
                    />
                    <p className="text-red-500">{sfD.mobileErrorMsg}</p>
                  </div>
                  <div className="mb-2">
                    <label
                      htmlFor="email"
                      className="block font-bold text-black mb-2"
                    >
                      Email
                    </label>
                    <input
                      onChange={(e) => changeInput(e.target.value, "email")}
                      name="email"
                      id="email"
                      type="email"
                      placeholder="email"
                      className="input-field"
                    />
                    <p className="text-red-500">{sfD.emailErrorMsg}</p>
                  </div>

                  <div className="mb-2">
                    <label
                      htmlFor="type"
                      className="block font-bold text-black mb-2"
                    >
                      Type
                    </label>

                    <select
                      id="type"
                      name="type"
                      onChange={(e) => changeInput(e.target.value, "type")}
                      className="input-field"
                    >
                      <option value="">Select Type</option>
                      <option value="1">Student</option>
                      <option value="2">Individual</option>
                    </select>
                    <p className="text-red-500">{sfD.typeErrorMsg}</p>
                  </div>
                  <button
                    onClick={(e) => handleSubmitForm(e, "Form3")}
                    className="bg-blue-500 input-button mt-4 hover:bg-blue-700 text-white font-bold py-4 px-8 w-full focus:outline-none focus:shadow-outline"
                  >
                    Next
                  </button>
                </div>
              </>
            ) : (
              <></>
            )}

            {showForm4 ? (
              <>
                <img src={address} width={"100%"} alt="fd" />
                <h2
                  className="text-2xl font-bold text-black mb-4"
                  style={{ fontFamily: "inherit" }}
                >
                  Address Details
                </h2>
                <p
                  className="text-md mb-4"
                  style={{ fontFamily: "inherit", color: "#71727A" }}
                >
                  Where's Your Happy Place? 🏡
                </p>
                <div>
                  <div className="mb-2">
                    <label
                      htmlFor="address1"
                      className="block font-bold text-black mb-2"
                    >
                      Address 1
                    </label>
                    <input
                      name="address1"
                      onChange={(e) => changeInput(e.target.value, "address1")}
                      id="address1"
                      type="text"
                      placeholder="Apartment , Unit , Building, Floor, Ect..."
                      className="input-field"
                    />
                    <p className="text-red-500">{sfD.address1ErrorMsg}</p>
                  </div>
                  <div className="mb-2">
                    <label
                      htmlFor="address2"
                      className="block font-bold text-black mb-2"
                    >
                      Adsress 2
                    </label>
                    <input
                      name="address2"
                      onChange={(e) => changeInput(e.target.value, "address2")}
                      id="address2"
                      type="text"
                      placeholder="Street Address , Area , P.O."
                      className="input-field"
                    />
                    <p className="text-red-500">{sfD.address2ErrorMsg}</p>
                  </div>
                  <div className="mb-2">
                    <label
                      htmlFor="city"
                      className="block font-bold text-black mb-2"
                    >
                      City
                    </label>
                    <input
                      name="city"
                      onChange={(e) => changeInput(e.target.value, "city")}
                      id="city"
                      type="text"
                      placeholder="Enter your city"
                      className="input-field"
                    />
                    <p className="text-red-500">{sfD.cityErrorMsg}</p>
                  </div>
                  <div className="mb-2">
                    <label
                      htmlFor="district"
                      className="block font-bold text-black mb-2"
                    >
                      District
                    </label>
                    <input
                      name="district"
                      onChange={(e) => changeInput(e.target.value, "district")}
                      id="district"
                      type="text"
                      placeholder="Enter your district"
                      className="input-field"
                    />
                    <p className="text-red-500">{sfD.districtErrorMsg}</p>
                  </div>

                  <div className="mb-2">
                    <label
                      htmlFor="type"
                      className="block font-bold text-black mb-2"
                    >
                      State
                    </label>
                    <select
                      id="state"
                      name="state"
                      onChange={(e) => changeInput(e.target.value, "state")}
                      className="input-field"
                    >
                      <option value="">Select state</option>
                      <option value="Andra Pradesh">Andra Pradesh</option>
                      <option value="Arunachal Pradesh">
                        Arunachal Pradesh
                      </option>
                      <option value="Assam">Assam</option>
                      <option value="Bihar">Bihar</option>
                      <option value="Chhattisgarh">Chhattisgarh</option>
                      <option value="Goa">Goa</option>
                      <option value="Gujarat">Gujarat</option>
                      <option value="Haryana">Haryana</option>
                      <option value="Himachal Pradesh">Himachal Pradesh</option>
                      <option value="Jammu and Kashmir">
                        Jammu and Kashmir
                      </option>
                      <option value="Jharkhand">Jharkhand</option>
                      <option value="Karnataka">Karnataka</option>
                      <option value="Kerala">Kerala</option>
                      <option value="Madya Pradesh">Madya Pradesh</option>
                      <option value="Maharashtra">Maharashtra</option>
                      <option value="Manipur">Manipur</option>
                      <option value="Meghalaya">Meghalaya</option>
                      <option value="Mizoram">Mizoram</option>
                      <option value="Nagaland">Nagaland</option>
                      <option value="Orissa">Orissa</option>
                      <option value="Punjab">Punjab</option>
                      <option value="Rajasthan">Rajasthan</option>
                      <option value="Sikkim">Sikkim</option>
                      <option value="Tamil Nadu">Tamil Nadu</option>
                      <option value="Telangana">Telangana</option>
                      <option value="Tripura">Tripura</option>
                      <option value="Uttaranchal">Uttaranchal</option>
                      <option value="Uttar Pradesh">Uttar Pradesh</option>
                      <option value="West Bengal">West Bengal</option>
                      <option
                        disabled
                        style={{ backgroundColor: "#aaa", color: "#fff" }}
                      >
                        UNION Territories
                      </option>
                      <option value="Andaman and Nicobar Islands">
                        Andaman and Nicobar Islands
                      </option>
                      <option value="Chandigarh">Chandigarh</option>
                      <option value="Dadar and Nagar Haveli">
                        Dadar and Nagar Haveli
                      </option>
                      <option value="Daman and Diu">Daman and Diu</option>
                      <option value="Delhi">Delhi</option>
                      <option value="Lakshadeep">Lakshadeep</option>
                      <option value="Pondicherry">Pondicherry</option>
                    </select>

                    <p className="text-red-500">{sfD.stateErrorMsg}</p>
                  </div>

                  <div className="mb-2">
                    <label
                      htmlFor="postalcode"
                      className="block font-bold text-black mb-2"
                    >
                      Postal Code
                    </label>
                    <input
                      name="postalcode"
                      onChange={(e) =>
                        changeInput(e.target.value, "postalcode")
                      }
                      id="postalcode"
                      type="number"
                      placeholder="Enter your postalcode"
                      className="input-field"
                    />
                    <p className="text-red-500">{sfD.postalcodeErrorMsg}</p>
                  </div>
                  <button
                    onClick={(e) => handleSubmitForm(e, "Form4")}
                    className="bg-blue-500 mt-4 input-button hover:bg-blue-700 text-white font-bold py-4 px-8 w-full focus:outline-none focus:shadow-outline"
                  >
                    Next
                  </button>
                </div>
              </>
            ) : (
              <></>
            )}

            {showForm5 ? (
              <>
                <img src={educational} width={"100%"} alt="fd" />

                <h2
                  className="text-4xl font-bold text-black mb-8"
                  style={{ display: "flex", fontFamily: "inherit" }}
                >
                  Educational Details&nbsp;&nbsp;&nbsp;{" "}
                  <div hidden={!showForm5Loader}>
                    <div className="loader"></div>
                  </div>
                </h2>
                <p
                  className="text-md mb-8"
                  style={{ fontFamily: "inherit", color: "#71727A" }}
                >
                  Let’s Get Academic! 🎓
                </p>
                <div>

                <div className="mb-2">
          <label
            htmlFor="education"
            className="block font-bold text-black mb-2"
          >
            Education
          </label>
          <input
            onChange={(e) => changeInput(e.target.value, "education")}
            name="education"
            id="education"
            type="text"
            placeholder="Enter your New school/college"
            className="input-field"
          />
          <p className="text-red-500">{sfD.educationErrorMsg}</p>
        </div>

        <div className="mb-2">
  <label htmlFor="state" className="block font-bold text-black mb-2">
    Select Your School
  </label>
  <select
  id="school"
  name="school"
  value={selectedSchool}
  onChange={(e) => {
    e.preventDefault()
    const selectedValue = e.target.value;
    console.log(selectedValue); // Logs the selected school ID
    changeInput(selectedValue, "school");
    setSelectedSchool(selectedValue);
    // if (selectedValue === "Others") {
    //   // Do something if "Others" is selected, like showing a text input for custom school
    //   // setSelectedSchool(true);
    // } else {
    //   // setSelectedSchool(false);
    // }
  }}
  className="input-field"
>
  <option value="0">Select Your School</option>
  {schools.map((school) => (
    <option key={school.id} value={school.id}>
      {school.school}
    </option>
  ))}
  <option value="Others">Others</option>
</select>

  <p className="text-red-500">{sfD.schoolErrorMsg}</p>
</div>

{selectedSchool === "Others" && (
  <div className="mb-2">
    <label
      htmlFor="new_school"
      className="block font-bold text-black mb-2"
    >
      School/College
    </label>
    <input
      onChange={(e) => changeInput(e.target.value, "new_school")}
      name="new_school"
      id="new_school"
      type="text"
      placeholder="Enter your New school/college"
      className="input-field"
    />
    <p className="text-red-500">{sfD.new_schoolErrorMsg}</p>
  </div>
)}
                  <div className="mb-2">
                    <label
                      htmlFor="institute"
                      className="block font-bold text-black mb-2"
                    >
                      Institute
                    </label>
                    <input
                      onChange={(e) => changeInput(e.target.value, "institute")}
                      name="institute"
                      id="institute"
                      type="text"
                      placeholder="Enter your school/college/institute"
                      className="input-field"
                    />
                    <p className="text-red-500">{sfD.instituteErrorMsg}</p>
                  </div>
                  <div className="mb-2">
                    <label
                      htmlFor="id_card_photo"
                      className="block font-bold text-black mb-2"
                    >
                      ID Card / Passport Size Photo
                    </label>
                    <div className="input-container id_cardcon">
                      <label htmlFor="id_card_photo" className="file-label">
                        Choose File
                        <input
                          id="id_card_photo"
                          type="file"
                          className="input-field id_cardfield"
                          name="id_card_photo"
                          onChange={handleFileChange}
                        />
                      </label>
                      <span className="ms-5">
                        {selectedFile ? selectedFile.name : ""}
                      </span>{" "}
                      {/* Display selected file name */}
                      {sfD.id_card_photoError}
                    </div>
                    <p className="text-red-500">{sfD.id_card_photoErrorMsg}</p>
                    <p className="text-sm text-gray-500">
                      We safeguard your ID card and photo, ensuring their
                      privacy and security
                    </p>
                  </div>
                  <button
                    onClick={(e) => handleSubmitForm(e, "Form5")}
                    className="bg-blue-500 input-button mt-4 hover:bg-blue-700 text-white font-bold py-4 px-8 w-full focus:outline-none focus:shadow-outline"
                  >
                    Next
                  </button>
                </div>
              </>
            ) : (
              <></>
            )}

            {/* {showForm6 ? (
              <>
                <h2 className="text-4xl font-bold text-black mb-8">
                  Create a password
                </h2>
                <p className="text-md mb-8">Set Your Key to Success 🔑</p>
                <div>
                  <div className="mb-2">
                    <label
                      htmlFor="password"
                      className="block font-bold text-black mb-2"
                    >
                      Create a password
                    </label>
                    <input
                      onChange={(e) => changeInput(e.target.value, "password")}
                      name="password"
                      id="password"
                      type="password"
                      placeholder="Enter your password"
                      className="input-field"
                    />
                    <p className="text-red-500">{sfD.passwordErrorMsg}</p>
                  </div>
                  <div className="mb-2">
                    <label
                      htmlFor="confirmpassword"
                      className="block font-bold text-black mb-2"
                    >
                      Confirm password
                    </label>
                    <div className="input-container">
                      <input
                        onChange={(e) =>
                          changeInput(e.target.value, "confirmpassword")
                        }
                        name="confirmpassword"
                        id="confirmpassword"
                        type="password"
                        placeholder="Enter your confirm password"
                        className="input-field"
                      />
                    </div>
                    <p className="text-red-500">
                      {sfD.confirmpasswordErrorMsg}
                    </p>
                  </div>
                  <button
                    onClick={(e) => {
                      handleSubmitForm(e, "Form6");
                    }}
                    className="bg-blue-500 mt-4 hover:bg-blue-700 text-white font-bold py-4 px-8 w-full focus:outline-none focus:shadow-outline"
                  >
                    Next
                  </button>
                </div>
              </>
            ) : (
              <></>
            )} */}

            {showForm6 ? (
              <>
                <div className="succestick">
                  <img src={righttick} />
                  <h2 className="text-2xl text-center font-bold text-black mb-8">
                    Registration successful
                  </h2>
                  <center>
                    {" "}
                    <p>Redirecting to packages {redirectingsec} s.</p>
                  </center>
                </div>
              </>
            ) : (
              <></>
            )}

            {showForm7 ? (
              <>
                {/* <div className="p-2 md:max-w-lg container mx-auto">
                  <h2 className="text-2xl font-bold text-black mb-8">
                  Activate Member Ship! 💳
                  </h2>
                  <h2 className="text-2xl font-bold text-black mb-8">
                  IDP (CO CURRICULUM ACTIVITIES) 
                  </h2>
                  <p  className="text-sm text-gray-500">
                  பள்ளிகள் அறிவு சார்ந்த கல்வியை சிறப்பான முறையில் போதித்து வருகின்றனர். மாணவர்களின் தனித் திறமைகளை வளர்க்கும் பொருட்டு ஒருங்கிணைந்த செயல்பாடுகள் என்னும் திட்டத்தின் கீழ் INTEGRATED DEVELOPMENT PROGRAM (IDP) அன்றாட நிகழ்வுகளை ஒழுங்கமைத்துக் கொள்ளவும், அறிவுக்கூர்மையை வளர்த்துக் கொள்ளவும், சமூக பங்களிப்புகளை செவ்வனே செய்யவும் பயிற்சி முறை திட்டமிடப்பட்டுள்ளது. 

                  </p>
                <br />
                <div style={{ position: "relative", padding: "10px" }}>
                  <div
                    className="paybox
                "
                  >
                    <h2>
                      <b>AYYA"WIN NALS SHOPPY</b>
                      <span style={{ float: "right" }}>
                        <b>₹999/-</b>
                      </span>
                    </h2>
                    <p>
                      <span>Description or discount</span>
                      <span style={{ float: "right", fontSize: "12px" }}>
                      90 Days 
                      </span>
                    </p>
                    <img className="staricon" src={star} alt="Star" />
                  </div>
                </div>
                <br />
                <div>
                  <h2>
                    <b>You'll get:</b>
                  </h2>
                </div>
                <div className="starbox">
                  <p className="flex items-center ">
                    <img className="mr-5" src={ministar} alt="" />
                    தினசரி செயல்பாடுகள் குறித்த பயிற்சிக் கையேடு
                  </p>
                  <p className="flex items-center ">
                    <img className="mr-5" src={ministar} alt="" />
                    மரத்தின் விதைகள் மற்றும் நர்சரி கவர்
                  </p>
                  <p className="flex items-center ">
                    <img className="mr-5" src={ministar} alt="" />
                    ரூ.260 மதிப்புள்ள சிறுதானிய உணவுப் பொருட்கள் தொகுப்பு
                  </p>
                  <p className="flex items-center ">
                    <img className="mr-5" src={ministar} alt="" />
                    வீட்டுத் தோட்ட உபகரணங்கள் தொகுப்பு மற்றும் கையேடு
                  </p>
                  <p className="flex items-center ">
                    <img className="mr-5" src={ministar} alt="" />
                    பங்கேற்பாளர் சான்றிதழ் மற்றும் மெடல்
                  </p>

                  <p className="mt-5 font-bold text-center">வகுப்புகள் அனைத்தும் ஆன்லைன் மூலமாக நடைபெறும்.</p>

<p className="font-bold text-center">மாவட்ட, மாநில அளவிலான திறன் மேம்பாட்டு நேரடிப் போட்டிகள் நடைபெறும். இடம் மற்றும் நேரம் பின்னர் அறிவிக்கப்படும்.</p>
                </div>
                <button
                  onClick={(e) => {
                    payamount()
                  }}
                  className="bg-blue-500 input-button mt-4 hover:bg-blue-700 text-white font-bold py-4 px-8 w-full focus:outline-none focus:shadow-outline"
                >
                  Pay
                </button>
                </div> */}
                <div className="succestick">
                  <img src={righttick} />
                  <h2 className="text-2xl text-center font-bold text-black mb-8">
                    Registration successful
                  </h2>
                  <center>
                    {" "}
                    <p>Redirecting to packages {redirectingsec} s.</p>
                  </center>
                </div>
              </>
            ) : (
              <></>
            )}
          </form>
        </div>
        <p
          className="text-black text-center text-md mt-4 mb-16"
          style={{ fontFamily: "Nunito Sans" }}
        >
          Already part of our community? Keep planting trees and connecting for
          a greener future! 🌱🌍 .<br></br>#Joinayyawinsfoundation ?
          <a
            href="/signin"
            className="text-blue-500 font-bold hover:text-blue-600"
          >
            &nbsp; Sign in
          </a>
        </p>
      </div>
    </div>
  );
};
