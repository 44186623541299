import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faPenToSquare,faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { TaskSubmit } from './TaskSubmit';

const ImageEditModal = ({ isOpen, onClose, currentId }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [textInput, setTextInput] = useState('');
  const [successMessage, setSuccessMessage] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [usertasklist, setUserTasklist] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [previewImages, setPreviewImages] = useState([]);
const [previewFiles, setPreviewFiles] = useState([]);

const handleFileChange = (event) => {
  const files = Array.from(event.target.files);
  setSelectedFiles(files);

  // Clear the preview files and set new previews
  setPreviewFiles([]);
  files.forEach((file, index) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreviewFiles(prev => [
        ...prev,
        { src: reader.result, type: file.type, id: index }
      ]);
    };
    reader.readAsDataURL(file);
  });
};

const handleRemoveFile = (fileId) => {
  setPreviewFiles(prev => prev.filter(file => file.id !== fileId));
  setSelectedFiles(prev => prev.filter((_, index) => index !== fileId));
};
  
  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userDataFromStorage = localStorage.getItem('userData');
        const userData = JSON.parse(userDataFromStorage);

        if (!userData || !userData.token) {
          throw new Error("User data or token is missing");
        }

        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${userData.token}`);

        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          redirect: "follow"
        };

        const response = await fetch(`https://api.ayyawinstudyfoundation.org/api/task/${currentId}`, requestOptions);

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const result = await response.json();
        if (result.success && result.data && result.data.task) {
          setUserTasklist(result.data.user_task_details);
        setTextInput(result.data.user_task_details.description);

        } else {
          throw new Error("Invalid response format");
        }
      } catch (error) {
        console.error(error);
      }
    };

    if (currentId) {
      fetchData();
    }
  }, [currentId]);
  

  let mediaContent = null;

  if (usertasklist.media_gallery) {
    try {
      // Parse the media_gallery JSON string to extract the URLs
      const mediaGallery = JSON.parse(usertasklist.media_gallery);
  
      // Check if mediaGallery is an array and has items
      if (Array.isArray(mediaGallery) && mediaGallery.length > 0) {
        mediaContent = mediaGallery.map((itemUrl, index) => {
          // Determine the type of media based on file extension
          const fileExtension = itemUrl.split('.').pop().toLowerCase();
  
          if (fileExtension === 'mp4' || fileExtension === 'webm') {
            // Video file extensions (adjust/add more if needed)
            return (
              <video  key={index} controls style={{width: "50%"}}>
                <source src={`https://api.ayyawinstudyfoundation.org/${itemUrl}`} type={`video/${fileExtension}`} />
                Your browser does not support the video tag.
              </video>
            );
          } else {
            // Assume it's an image if not a recognized video extension
            return <img key={index} style={{width: "50%"}} src={`https://api.ayyawinstudyfoundation.org/${itemUrl}`} alt={`Task Image ${index + 1}`} />;
          }
        });
      }
    } catch (error) {
      console.error("Error parsing media_gallery JSON:", error);
    }
  }
  
const handleUpload = async () => {
  setIsLoading(true);

  // Simulate an async process (e.g., API call)
  const userDataFromStorage = localStorage.getItem('userData');
  const userData = JSON.parse(userDataFromStorage);

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${userData.token}`);

  const formdata = new FormData();
  formdata.append("status", "1");
  formdata.append("description", textInput);

  // Append each file individually
  if (selectedFiles && selectedFiles.length > 0) {
    selectedFiles.forEach(file => {
      formdata.append('media_gallery[]', file);
    });
  }

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow"
  };

  try {
    const response = await fetch(`https://api.ayyawinstudyfoundation.org/api/task/update/${currentId}`, requestOptions);

    if (response.ok) {
      const result = await response.text();
      console.log(result);
      setSuccessMessage(true);
      setIsAnimating(true);
      setSelectedFiles([]); // Clear file input
      setPreviewFiles([]); // Clear image previews

      // Close modal after animation
      setTimeout(() => {
        setIsLoading(false);
        setIsAnimating(false);
        onClose();
        setSuccessMessage(false);
      }, 2000); // Duration of the animation
    } else {
      console.error('Upload failed:', response.statusText);
      setIsLoading(false);
    }
  } catch (error) {
    console.error(error);
    setIsLoading(false);
  } finally {
    setIsLoading(false);
  }
};


  if (!isOpen) return null;



  return (
 <div className={`fixed inset-0 bg-black bg-opacity-50 ${isOpen ? 'block' : 'hidden'}`}>
  <div className="flex items-center justify-center min-h-screen">
    <div className="bg-white p-6 rounded-md shadow-md w-11/12 sm:w-1/3 max-h-screen overflow-y-auto">
      <h2 className="text-lg font-medium mb-4">Edit Task</h2>
      <p className="mb-4">Task Day: {currentId}</p>
      <textarea
        value={textInput}
        onChange={(e) => setTextInput(e.target.value)}
        placeholder="Enter text"
        className="mb-4 block w-full p-2 border border-gray-300 rounded-md"
      />
      {mediaContent}
      <input
        type="file"
        multiple
        onChange={handleFileChange}
        className="mb-4 block w-full text-sm mt-5 text-gray-500
                   file:mr-4 file:py-2 file:px-4
                   file:rounded-full file:border-0
                   file:text-sm file:font-semibold
                   file:bg-violet-50 file:text-violet-700
                   hover:file:bg-violet-100"
      />
      <div className="mt-4">
 {previewFiles.map((file, index) => (
          <div key={index} style={{ position: 'relative', display: 'inline-block', margin: '10px' }}>
            {file.type.startsWith('image/') ? (
              <img
                src={file.src}
                alt={`preview ${index}`}
                style={{ width: '100px', height: '100px' }}
              />
            ) : file.type.startsWith('video/') ? (
              <video
                src={file.src}
                controls
                style={{ width: '100%', height: '100%' }}
              />
            ) : null}
            <button
              onClick={() => handleRemoveFile(file.id)}
              style={{ position: 'absolute', top: '0', right: '0',padding:'5px 10px', backgroundColor: 'red', color: 'white', border: 'none', borderRadius: '50%' }}
            >
              X
            </button>
          </div>
        ))}

      </div>
      <div className={`transition-opacity duration-1000 ${successMessage ? 'opacity-100' : 'opacity-0'}`}>
        {successMessage && <div className="bg-green-500 text-white p-2 rounded-md">Update successful!</div>}
      </div>
      <div className="flex justify-end">
        <button
          onClick={onClose}
          className="bg-red-500 text-white px-4 py-2 rounded-md mr-2"
        >
          Close
        </button>
        <button
          onClick={handleUpload}
          className="bg-blue-500 text-white px-4 py-2 rounded-md"
          disabled={isLoading}
        >
          {isLoading ? (
            <svg
              className="animate-spin h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8v8H4z"
              ></path>
            </svg>
          ) : (
            'Update'
          )}
        </button>
      </div>
    </div>
  </div>
</div>

  );
};

export const Task = () => {
  const [showContent, setShowContent] = useState(true);
  const [taskdata, setTaskdata] = useState(null);
  const [taskday, setTaskday] = useState(null);
  const [tasklist, setTasklist] = useState(null);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentId, setCurrentId] = useState(null);

  useEffect(() => {
    if (showContent) {
      window.scrollTo(0, 0); 
    }
  }, [showContent]);

  useEffect(() => {
    const fetchData = async () => {
      const myHeaders = new Headers();
      const userDataFromStorage = localStorage.getItem('userData');
      const userData = JSON.parse(userDataFromStorage);

      myHeaders.append("Authorization", `Bearer ${userData?.token}`);

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
      };

      try {
        const response = await fetch("https://api.ayyawinstudyfoundation.org/api/tasks", requestOptions);
        const data = await response.json();
        setTaskdata(data.data.tasks);

        const tasks = data.data.tasks;
        let firstEnabledTask = null;
        for (let i = 0; i < tasks.length; i++) {
          if (!tasks[i].disabled) {
            firstEnabledTask = tasks[i];
            break;
          }
        }
        if (firstEnabledTask) {
          setTaskday(firstEnabledTask.id);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userDataFromStorage = localStorage.getItem('userData');
        const userData = JSON.parse(userDataFromStorage);

        if (!userData || !userData.token) {
          throw new Error("User data or token is missing");
        }

        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${userData.token}`);

        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          redirect: "follow"
        };

        const response = await fetch(`https://api.ayyawinstudyfoundation.org/api/task/${taskday}`, requestOptions);

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const result = await response.json();
        if (result.success && result.data && result.data.task) {
          setTasklist(result.data.task);
          setError(null);
        } else {
          throw new Error("Invalid response format");
        }
      } catch (error) {
        console.error(error);
        setError(error.message);
      }
    };

    if (taskday) {
      fetchData();
    }
  }, [taskday]);

  const handleOpenModal = (id) => {
    setCurrentId(id);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setCurrentId(null);
  };

  const handleUpdate = (file, text) => {
    console.log('Updated file:', file);
    console.log('Text input:', text);
    console.log('Current ID:', currentId);
    handleCloseModal();
  };

  const handleStartClick = () => {
    setShowContent(false);
  };

  const userDataFromStorage = localStorage.getItem('userData');
  const userData = JSON.parse(userDataFromStorage);

  return (
    <>
      {showContent ? (
        <div className="container mx-auto p-4 block md:flex">
          <div className="maintaskpage p-0 md:p-10 w-full md:w-3/5">
            <h5><span className="uppercase">{userData?.name} ' s</span> Current Task</h5>
            <h6>Day {tasklist?.id} - {tasklist?.title}</h6>
            <p>
              {tasklist?.content}
            </p>
            <button
              className="bg-blue-600 mt-6 input-button hover:bg-blue-700 text-white font-bold py-4 px-8 w-full mb-8 focus:outline-none focus:shadow-outline"
              onClick={handleStartClick}
            >
              Start
            </button>
          </div>
          <div className="subtasksection w-full md:w-3/5">


            {taskdata?.slice().reverse().map((value, index) => (
              <div key={value.id} className="block">
                <div className="flex justify-between items-start my-4">
                <div>
                <h6 className='uppercase'>Day {index + 1} - {value.title}</h6>

                  <p>{value.description}</p>
                  </div>
                  <>
                 {value.task_status === 1 && value.disabled ? (<div className='com-btn'>
        <button className='completedbutton' style={{ pointerEvents: 'none' }}>
          <FontAwesomeIcon icon={faCircleCheck} style={{ color: "#ffffff" }} />
        </button>
{/* <button className="mb-4 block w-full text-sm mt-1 hover:text-white
                   bg-red-100 hover:bg-red-300 text-red-800
                   rounded-full border-0
                   font-semibold">
  Reopen
</button> */}


</div>
      ) : value.task_status === 1 ? (
        <button className='editbutton2' >
          <FontAwesomeIcon icon={faPenToSquare} onClick={() => handleOpenModal(value.id)} style={{ color: "#ffffff" }} />
        </button>
      ) : value.disabled ? (
        <button className="editbutton" >
          <FontAwesomeIcon icon={faLock} style={{ color: '#ffffff' }} />
        </button>
      ) : (
        <button className="editbutton1" >
          -
        </button>
      )}</>
    
                </div>
                <hr className='pb-5'/>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <TaskSubmit />
      )}

      <ImageEditModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        currentId={currentId}
      />
    </>
  );
};

 
